import Button from "@mui/material/Button";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useGetPermitsQuery } from "services/rtkApi/endpoints/permits";
import {
  EventCustomFieldDto,
  LynxGridColDef,
  PermitDto,
  RootState,
} from "types";
import { EntityTypes } from "types/enums-ts";
import {
  getCustomFields,
  transformCustomFieldDefsToColumnDefs,
} from "../../../services/custom-fields-service";
import { getPermitExportAsXLSX } from "../../../services/permit-service";
import { LynxDataGrid } from "../../data-grid/lynx-data-grid";
import { permitColumns } from "./permit-columns";
import { PermitMobileCard } from "./permit-mobile-card";

export function PermitList() {
  var account = useSelector((state: RootState) => state.account);
  const history = useHistory();
  const [columns, setColumns] = useState<LynxGridColDef[]>([]);
  const [eventCustomFields, setEventCustomFields] = useState<
    EventCustomFieldDto[] | null
  >(null);
  const { users } = useSelector((state: RootState) => state.lookups);
  const predefinedLookups = { users: users };

  useEffect(() => {
    let newColumns = [...permitColumns(predefinedLookups)];

    let colIndex = newColumns.findIndex((x) => x.field == "permitNumber");
    newColumns[colIndex].renderCell = (params: any) => {
      return (
        <Button
          variant="text"
          className="permit-number-button"
          onClick={() => handlePermitNumberClick(params.row)}
        >
          {params.value}
        </Button>
      );
    };
    setColumns(newColumns);
  }, [permitColumns]);

  useEffect(() => {
    getCustomFields(EntityTypes.Permit)
      .then((res) => {
        let eventCustomFields = res.data;
        setEventCustomFields(eventCustomFields);
      })
      .catch((err) => {
        setColumns(permitColumns(predefinedLookups));
      });
  }, []);

  useEffect(() => {
    if (eventCustomFields != null && !account.isLoading) {
      let newColumns = [
        ...permitColumns(predefinedLookups),
        ...transformCustomFieldDefsToColumnDefs(eventCustomFields),
      ];
      setColumns(newColumns);
    }
  }, [eventCustomFields, account.isLoading]);

  const navigateToAddPermit = () => {
    history.push(`/add-permit`);
  };

  const handlePermitNumberClick = (row: PermitDto) => {
    history.push(`/permits/${row.id}`);
  };

  return (
    <>
      {!_.isEmpty(columns) && (
        <LynxDataGrid
          enableSavedFilters
          columns={columns}
          useQuery={useGetPermitsQuery}
          title="Permits"
          localStorageName="permits"
          entityType={EntityTypes.Permit}
          addButtonAction={navigateToAddPermit}
          addButtonText="Add Permit"
          mobileCard={PermitMobileCard}
          handleExportXLSX={getPermitExportAsXLSX}
        />
      )}
    </>
  );
}
