import LinkIcon from "@mui/icons-material/Link";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Grid } from "components/lynx-components";
import useAlert from "hooks/useAlert";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ActionDto } from "types";
import { dateUtil } from "../../../services/date-util";
import { Chip } from "@mui/material";
import lynxColors from "modules/lynxColors";
import { ActionStatuses } from "types/enums";
import { getAction } from "services/action-service";

const getStatusChip = (action: ActionDto) => {
  const status = action.status;
  if (status) {
    if ([ActionStatuses.Approved].includes(status)) {
      return <Chip label={status} color="success" />;
    }
    if (
      [
        ActionStatuses.PendingApproval,
        ActionStatuses.PendingVerification,
      ].includes(status)
    ) {
      return (
        <Chip
          label={status}
          sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
        />
      );
    }
    if (status === ActionStatuses.Overdue) {
      return (
        <Chip
          label={status}
          sx={{ backgroundColor: lynxColors.error, color: "white" }}
        />
      );
    }
    return <Chip label={status} color="primary" />;
  }
};

interface Props {
  action: ActionDto;
  actionId: number;
  handleClose: () => void;
}

const ActionQuickView: FC<Props> = (props) => {
  const [action, setAction] = useState<ActionDto>(props.action);
  const history = useHistory();
  const { showAlert } = useAlert();

  useEffect(() => {
    if (props.actionId > 0) {
      getAction(props.actionId).then((res) => {
        setAction(res.data);
      });
    }
  }, [props.action, props.actionId]);

  const handleSourceNumberClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (action) {
      history.push(`/actions/${action.id}`);
    }
  };

  const handleLinkClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    navigator.clipboard.writeText(`${window.origin}/actions/${action?.id}`);
    showAlert("success", "Link copied!");
  };
  return (
    <>
      <DialogContent
        sx={{ padding: "10px 12px", minWidth: "550px", maxWidth: "700px" }}
      >
        <Grid>
          <Grid.Row>
            <Grid.Col width={10}>
              <div className="d-flex flex-wrap align-items-center mb-1">
                <Link
                  href="#"
                  onClick={handleSourceNumberClick}
                  underline="hover"
                  className="mr-2"
                >
                  <Typography variant="h6" component="span">
                    {action?.actionNumber} - {action?.category}
                  </Typography>
                </Link>

                <div>{getStatusChip(action)}</div>
              </div>
            </Grid.Col>
            <Grid.Col width={2}>
              <div className="d-flex flex-wrap align-items-center mb-1">
                <div className="ml-auto">
                  <Tooltip title="Copy link">
                    <IconButton onClick={handleLinkClick}>
                      <LinkIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col md={6} width={12}>
              {action?.dueDate && (
                <Typography variant="subtitle2">
                  Due:&nbsp;
                  {dateUtil.convertDateOnlyToLocal(action?.dueDate)}
                </Typography>
              )}
            </Grid.Col>
            <Grid.Col md={6} width={12}>
              <Typography variant="subtitle2">{action?.assetNames}</Typography>
            </Grid.Col>
          </Grid.Row>

          <Divider className="mt-2 mb-2" />
          <Grid.Row className="mt-2">
            <Grid.Col md={6} width={12}>
              <Typography variant="body1">
                <b>Priority:</b>&nbsp;{action?.priority || "None"}
              </Typography>
            </Grid.Col>
            {action?.assignedToUserFullName && (
              <Grid.Col md={6} width={12}>
                <Typography variant="body1">
                  <b>Assigned To: </b>
                  {action.assignedToUserFullName}
                </Typography>
              </Grid.Col>
            )}
          </Grid.Row>
          {action?.description && (
            <Grid.Row className="mt-2">
              <Grid.Col md={12} width={12}>
                <Typography variant="body1">
                  <b>Description: </b>
                  {action.description}
                </Typography>
              </Grid.Col>
            </Grid.Row>
          )}
          <Divider className="mt-2 mb-2" />
          <Grid.Row>
            <Grid.Col md={6} width={12}>
              <Typography variant="body1">
                <b>Evidence required? </b>
                {action?.evidenceRequired ? "Yes" : "No"}
              </Typography>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Close</Button>
      </DialogActions>
    </>
  );
};

export default ActionQuickView;
