import queryString from "query-string";
import { apiService } from "../apiService";
import { RtkTagTypes } from "types/enums";

const BASE_ROUTE = "/monitoringVarianceLimits";

const monitoringVarianceLimitsEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    // Fetch monitoring variance limits with pagination
    getMonitoringVarianceLimits: builder.query({
      query: (baseParams: any) =>
        `${BASE_ROUTE}?${queryString.stringify(baseParams)}`,
      providesTags: [RtkTagTypes.GetMonitoringVarianceLimits],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),

    // Create a new monitoring variance limit
    createMonitoringVarianceLimit: builder.mutation({
      query: (dto: any) => ({
        url: BASE_ROUTE,
        method: "POST",
        body: dto,
      }),
      invalidatesTags: [RtkTagTypes.GetMonitoringVarianceLimits],
    }),

    // Update an existing monitoring variance limit
    updateMonitoringVarianceLimit: builder.mutation({
      query: ({ id, dto }: { id: number; dto: any }) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "PUT",
        body: dto,
      }),
      invalidatesTags: (result: any, error: any, { id }: any) => [
        { type: RtkTagTypes.GetMonitoringVarianceLimits, id },
        RtkTagTypes.GetMonitoringVarianceLimits,
      ],
    }),

    // Delete a monitoring variance limit
    deleteMonitoringVarianceLimit: builder.mutation({
      query: (id: number) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetMonitoringVarianceLimits],
    }),
  }),
});

export const {
  useGetMonitoringVarianceLimitsQuery,
  useCreateMonitoringVarianceLimitMutation,
  useUpdateMonitoringVarianceLimitMutation,
  useDeleteMonitoringVarianceLimitMutation,
} = monitoringVarianceLimitsEndpoint;
