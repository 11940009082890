import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import { Card, Dimmer, Form, Grid } from "components/lynx-components";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { getLookups } from "./../../../services/lookup";
import { getParameters } from "./../../../services/parameters";

import EditIcon from "@mui/icons-material/Edit";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import useAlert from "hooks/useAlert";
import localforage from "localforage";
import moment from "moment";
import queryString from "query-string";
import { useSelector } from "react-redux";
import {
  useDeleteMonitoringResultMutation,
  useGetMonitoringResultsQuery,
  useUpdateMonitoringResultMutation,
} from "services/rtkApi/endpoints/monitoringResults";
import { roleMatch } from "../../../actions/auth";
import {
  getCustomFields,
  setupInitialCustomFieldState,
  transformCustomFieldState,
} from "../../../services/custom-fields-service";
import { createGuid } from "../../../services/events";

import { getMonitoringLocations } from "../../../services/monitoring-location-service";

import {
  eventSyncStore,
  eventTypeStoreName,
  monLimitStore,
  monLocStoreName,
  monitoringCustomFieldStoreName,
  monitoringEventsStore,
  parameterStoreName,
  unitStoreName,
} from "../../../services/offlineDataService";
import { EntityTypes, UserRoles } from "../../../types/enums";
import PageNotFound from "../../PageNotFound";
import { Accordion, AccordionDetails, AccordionSummary } from "../../accordion";
import { LynxDataGrid } from "../../data-grid/lynx-data-grid";
import { LocationSelectionControl } from "../../location-selection-control";
import CustomField from "../events/custom-field";
import useOnlineStatus from "./../../../hooks/useOnlineStatus";

import { getMonitoringLimits } from "./../../../services/monitoring-limits";
import { resultTypeStoreName } from "./../../../services/offlineDataService";
import { validationService } from "./../../../services/validation";
import { LookupTypes } from "./../../../types/enums";
import { AttachmentViewer } from "./../../attachment-viewer";
import { SearchableSelect } from "./../../form-controls/searchable-select";
import { LightTooltip } from "./../../light-tooltip";
import { LynxDialog } from "./../../lynx-dialog";
import "./add-monitoring-event.css";
import { monitoringEventResultColumns } from "./mon-event-result-columns";
import {
  useAddMonitoringEventMutation,
  useDeleteMonitoringEventMutation,
  useLazyGetMonitoringEventQuery,
  useUpdateMonitoringEventMutation,
} from "services/rtkApi/endpoints/monitoringEvents";
import { useHistory, useLocation, useParams } from "react-router-dom";

export function AddMonitoringEvent(props) {
  const initalFrom = {
    monitoringLocationId: "",
    name: "",
    startDateTimeUtc: "",
    endDateTimeUtc: "",
    eventTypeId: "",
    monitoringLocationIdError: "",
    nameError: "",
    endDateTimeUtcError: "",
    eventTypeIdError: "",
    monitoringEventNumber: "",
    description: "",
    latitude: "",
    longitude: "",
    dueDateTimeUtc: "",
    dueDateTimeUtcError: "",
    assignedToUserId: "",
    isConfidential: false,
    customFieldData: {},
    newResults: [],
  };
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCustomFields, setSelectedCustomFields] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [formState, setFormState] = useState(initalFrom);
  const [monitoringLocations, setMonitoringLocations] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [locationExpanded, setLocationExpanded] = useState(false);
  const [initialFormState, setInitialFormState] = useState({});
  const [parameters, setParameters] = useState([]);
  const [units, setUnits] = useState([]);
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [unitsLoading, setUnitsLoading] = useState(true);
  const [resultTypesLoading, setResultTypesLoading] = useState(true);
  const [eventTypesLoading, setEventTypesLoading] = useState(true);
  const [parametersLoading, setParametersLoading] = useState(true);
  const [monitoringLocationsLoading, setMonitoringLocationsLoading] =
    useState(true);
  const [customFieldsLoading, setCustomFieldsLoading] = useState(true);
  const [createTrigger] = useAddMonitoringEventMutation();
  const [updateTrigger] = useUpdateMonitoringEventMutation();
  const [deleteTrigger] = useDeleteMonitoringEventMutation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const templateOpen = Boolean(anchorEl);
  const [formTouched, setFormTouched] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [isOfflineReady, setIsOfflineReady] = useState(true);
  const [monitoringLimits, setMonitoringLimits] = useState([]);
  const [defaultLatitude, setDefaultLatitude] = useState("");
  const [defaultLongitude, setDefaultLongitude] = useState("");
  const [locationTouched, setLocationTouched] = useState(false);
  const isOffline = !useOnlineStatus();
  const [detailsExpanded, setDetailsExpanded] = useState(true);
  const [resultsExpanded, setResultsExpanded] = useState(true);
  const [customFieldsExpanded, setCustomFieldsExpanded] = useState(true);
  const [attachmentsExpanded, setAttachmentsExpanded] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [selectedResult, setSelectedResult] = useState({});
  const [customFieldState, setCustomFieldState] = useState({});
  const [resultTypes, setResultTypes] = useState([]);
  const [showNotes, setShowNotes] = useState(false);
  const [showDeleteParamDialog, setShowDeleteParamDialog] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [existingCustomFieldValues, setExistingCustomFieldValues] =
    useState(null);
  const [resultToDelete, setResultToDelete] = useState(null);
  const [showEditResult, setShowEditResult] = useState(false);
  const [columns, setColumns] = useState([]);
  const [resultFormState, setResultFormState] = useState({});

  const [importHistories, setImportHistories] = useState([]);
  const [loadTrigger, { error }] = useLazyGetMonitoringEventQuery();
  const [updateResultTrigger] = useUpdateMonitoringResultMutation();
  const [deleteResultTrigger] = useDeleteMonitoringResultMutation();
  const allUsers = useSelector((state) => state.lookups.users);
  const users = useMemo(
    () => allUsers?.filter((x) => x.isMonitoringUser) || [],
    [allUsers]
  );
  const account = useSelector((state) => state.account);

  const { showAlert } = useAlert();
  const history = useHistory();

  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    if (
      unitsLoading === false &&
      resultTypesLoading === false &&
      eventTypesLoading === false &&
      parametersLoading === false &&
      monitoringLocationsLoading === false &&
      customFieldsLoading === false &&
      isFormLoading === false
    ) {
      setIsLoading(false);
    }
  }, [
    unitsLoading,
    resultTypesLoading,
    eventTypesLoading,
    parametersLoading,
    monitoringLocationsLoading,
    customFieldsLoading,
    isFormLoading,
  ]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDeleteExistingResult = (result) => {
    setShowDeleteParamDialog(true);
    setResultToDelete(result);
  };
  useEffect(() => {
    let newColumns = [...monitoringEventResultColumns];
    let colIndex = monitoringEventResultColumns.findIndex(
      (x) => x.field == "edit"
    );
    if (isFormLocked) {
      newColumns.splice(colIndex, 1);
    } else {
      newColumns[colIndex].renderCell = (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                className="grid-edit-button"
                onClick={() => {
                  handleResultEdit(params.row);
                }}
                aria-label="Edit"
                size="small"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            {roleMatch([UserRoles.MonitoringAdministrator]) && (
              <Tooltip title="Remove" className="ml-1">
                <IconButton
                  onClick={() => {
                    handleDeleteExistingResult(params.row);
                  }}
                  className="grid-remove-button"
                  aria-label="Remove"
                  size="small"
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      };
    }

    setColumns(newColumns);
  }, [monitoringEventResultColumns]);

  useEffect(() => {
    if (formState.monitoringLocationId) {
      loadMonitoringLimits(formState.monitoringLocationId);
    }
  }, [formState.monitoringLocationId, monitoringLocations]);

  useEffect(() => {
    //setup custom fields on initial form for new events
    if (!isExistingEvent() && !_.isEmpty(customFields)) {
      let newCustomFieldState = setupInitialCustomFieldState(customFields);
      setCustomFieldState(newCustomFieldState);
    }

    if (
      isExistingEvent() &&
      !_.isEmpty(customFields) &&
      existingCustomFieldValues
    ) {
      let newCustomFieldState = setupInitialCustomFieldState(
        customFields,
        existingCustomFieldValues
      );

      setCustomFieldState(newCustomFieldState);
    }
  }, [customFields, params.eventId, existingCustomFieldValues]);

  useEffect(() => {
    loadLookups();
    if (params.eventId) {
      loadEvent();
      setFormTouched(true);
    } else {
      if (params.monLocId) {
        let newState = {
          ...formState,
          monitoringLocationId: params.monLocId,
        };
        setFormState(newState);
      }
      setIsFormLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(customFields)) {
      let newCustomFields = [
        ...customFields.filter((x) => x.parentLookupId == null),
      ];
      if (formState.eventTypeId) {
        newCustomFields = [
          ...newCustomFields,
          ...customFields.filter(
            (x) => x.parentLookupId == formState.eventTypeId
          ),
        ];
      }

      setSelectedCustomFields(newCustomFields);
    }
  }, [formState.eventTypeId, customFields]);

  const handleResultEdit = (result) => {
    let newResultFormState = {
      id: result.id,
      parameterId: result.parameterId,
      parameterIdError: "",
      enteredValue: result.enteredValue,
      enteredValueError: "",
      unitId: result.unitId,
      unitIdError: "",
      analysisMethod: result.analysisMethod,
      enteredMethodDetectionLimit: result.enteredMethodDetectionLimit,
      enteredReportingDetectionLimit: result.enteredReportingDetectionLimit,
      resultTypeId: result.resultTypeId,
      sequence: result.sequence,
      notes: result.notes,
    };
    setResultFormState(newResultFormState);
    setShowEditResult(true);
  };

  const isScheduledEvent = () => {
    //event is scheduled if
    if (formState.endDateTimeUtc != "") {
      return false;
    }
    //user presses schedule event
    const values = queryString.parse(location.search);
    if (values.isScheduled) {
      return true;
    }

    if (isExistingEvent() && initialFormState.endDateTimeUtc == "") {
      return true;
    }

    return false;
  };

  const getImportAttachments = () => {
    if (!_.isEmpty(importHistories)) {
      return importHistories.map((history) => ({
        importHistoryId: history.id,
        fileName: history.fileName,
        isImport: true,
        entityAttachmentId: null,
        filePath: history.filePath,
        importDateTimeUtc: history.importDateTimeUtc,
        uploaderUserFullName: history.uploaderUserFullName,
      }));
    }
  };

  const handleLatLongChange = (lat, long) => {
    setFormState((existingState) => {
      return { ...existingState, latitude: lat, longitude: long };
    });
  };
  const isExistingEvent = () => {
    return params.eventId;
  };

  const loadEvent = () => {
    loadTrigger(params.eventId).then((res) => {
      if (res.data) {
        setImportHistories(res.data.importHistories);
        setFormStateFromProps(res.data);
      }
    });
  };

  const setFormStateFromProps = (event) => {
    let startDate = event.startDateTimeUtc
      ? moment(event.startDateTimeUtc).format("YYYY-MM-DD HH:mm")
      : "";
    let endDate = event.endDateTimeUtc
      ? moment(event.endDateTimeUtc).format("YYYY-MM-DD HH:mm")
      : "";
    setExistingCustomFieldValues(event.eventCustomFieldValues);
    let newState = {
      startDateTimeUtc: startDate,
      endDateTimeUtc: endDate,
      dueDateTimeUtc: event.dueDateTimeUtc,
      assignedToUserId: event.assignedToUserId,
      monitoringLocationId: event.monitoringLocationId,
      eventTypeId: event.eventTypeId,
      name: event.name,
      description: event.description,
      monitoringEventNumber: event.monitoringEventNumber,
      latitude: event.latitude,
      longitude: event.longitude,
      customFieldData: { ...event.customFieldData },
      newResults: [],
    };

    setInitialFormState(_.cloneDeep(newState));
    setDefaultLatitude(event.latitude);
    setDefaultLongitude(event.longitude);
    setFormState(newState);
    setIsFormLoading(false);
  };

  const loadLookups = () => {
    loadUnits();
    loadResultTypes();
    loadEventTypes();
    loadParameters();
    loadMonitoringLocations();
    loadCustomFields();
  };

  const loadCustomFields = () => {
    getCustomFields(EntityTypes.MonitoringEvent)
      .then((res) => {
        let customFieldsToSet = res.data.filter((x) => !x.isDeleted);
        setCustomFields(customFieldsToSet);
        localforage.setItem(monitoringCustomFieldStoreName, customFieldsToSet);
        setCustomFieldsLoading(false);
      })
      .catch(() => {
        //if offline or error, load from indexDb.
        localforage.getItem(monitoringCustomFieldStoreName).then((data) => {
          setCustomFields(data);
        });
        setCustomFieldsLoading(false);
      });
  };

  const loadMonitoringLimits = (monLocId) => {
    getMonitoringLimits({ monitoringLocationId: monLocId, pageSize: 100000 })
      .then((res) => {
        setMonitoringLimits(res.data);
        localforage.setItem(monLimitStore, res.data);
      })
      .catch((err) => {
        localforage.getItem(monLimitStore).then((data) => {
          setMonitoringLimits(data);
        });
      });
  };

  const loadUnits = () => {
    getLookups(LookupTypes.Unit)
      .then((res) => {
        setUnits(res.data);
        localforage.setItem(unitStoreName, res.data);
        setUnitsLoading(false);
      })
      .catch((err) => {
        //if offline or error, load from indexDb.
        localforage.getItem(unitStoreName).then((data) => {
          setUnits(data.sort((a, b) => a.code.localeCompare(b.code)));
        });
        setUnitsLoading(false);
      });
  };

  const loadResultTypes = () => {
    getLookups(LookupTypes.MonitoringResultType)
      .then((res) => {
        setResultTypes(res.data);
        localforage.setItem(resultTypeStoreName, res.data);
        setResultTypesLoading(false);
      })
      .catch((err) => {
        //if offline or error, load from indexDb.
        localforage.getItem(resultTypeStoreName).then((data) => {
          setResultTypes(data.sort((a, b) => a.code.localeCompare(b.code)));
        });
        setResultTypesLoading(false);
      });
  };

  const loadParameters = () => {
    getParameters()
      .then((res) => {
        setParameters(res.data);
        localforage.setItem(parameterStoreName, res.data);
        setParametersLoading(false);
      })
      .catch((err) => {
        localforage.getItem(parameterStoreName).then((data) => {
          setParameters(data.sort((a, b) => a.name.localeCompare(b.name)));
        });
        setParametersLoading(false);
      });
  };
  const loadEventTypes = () => {
    getLookups("MonitoringEventType")
      .then((res) => {
        setEventTypes(res.data);
        localforage.setItem(eventTypeStoreName, res.data);
        setEventTypesLoading(false);
      })
      .catch((err) => {
        //if offline or error, load from indexDb.

        localforage.getItem(eventTypeStoreName).then((data) => {
          setEventTypes(data.sort((a, b) => a.code.localeCompare(b.code)));
        });
        setEventTypesLoading(false);
      });
  };
  const loadMonitoringLocations = () => {
    getMonitoringLocations({
      pageSize: 100000,
    })
      .then((res) => {
        let data = res.data;
        if (params.id) {
          data = data.filter((x) => x.assetId == params.id);
        }
        if (params.monLocId && !isExistingEvent()) {
          const monLoc = data.find((x) => x.id == params.monLocId);
          setDefaultLatitude(monLoc.latitude);
          setDefaultLongitude(monLoc.longitude);
        }
        setMonitoringLocations(data);
        localforage.setItem(monLocStoreName, data);
        setMonitoringLocationsLoading(false);
      })
      .catch(() => {
        //if offline or error, load from indexDb.
        localforage.getItem(monLocStoreName).then((data) => {
          if (params.id) {
            data = data.filter((x) => x.assetId == params.id);
          }
          setMonitoringLocations(
            data.sort((a, b) => a.name.localeCompare(b.name))
          );
        });
        setMonitoringLocationsLoading(false);
      });
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let newCustomFieldState = { ...customFieldState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "monitoringLocationId",
      "monitoringLocationIdError",
      "Monitoring Location"
    );

    validationService.validateRequiredField(
      newState,
      "name",
      "nameError",
      "Name"
    );
    if (!isScheduledEvent()) {
      validationService.validateRequiredField(
        newState,
        "endDateTimeUtc",
        "endDateTimeUtcError",
        "End Date"
      );
    } else {
      validationService.validateRequiredField(
        newState,
        "dueDateTimeUtc",
        "dueDateTimeUtcError",
        "Due Date"
      );
    }

    validationService.validateRequiredField(
      newState,
      "eventTypeId",
      "eventTypeIdError",
      "Event Type"
    );
    if (!isScheduledEvent()) {
      customFields
        .filter(
          (x) =>
            x.isRequired &&
            !x.isDeleted &&
            (_.toLower(x.fieldType) == "dropdown" ||
              _.toLower(x.fieldType) == "text" ||
              _.toLower(x.fieldType) == "textarea" ||
              _.toLower(x.fieldType) == "multiselect")
        )
        .forEach((value) => {
          validationService.validateRequiredField(
            newCustomFieldState,
            `${value.fieldName}.textValue`,
            `${value.fieldName}Error`,
            `${value.fieldLabel}`
          );
        });
      customFields
        .filter(
          (x) =>
            x.isRequired && !x.isDeleted && _.toLower(x.fieldType) == "datetime"
        )
        .forEach((value) => {
          validationService.validateRequiredField(
            newCustomFieldState,
            `${value.fieldName}.dateTimeValue`,
            `${value.fieldName}Error`,
            `${value.fieldLabel}`
          );
        });
      customFields
        .filter(
          (x) =>
            x.isRequired && !x.isDeleted && _.toLower(x.fieldType) == "date"
        )
        .forEach((value) => {
          validationService.validateRequiredField(
            newCustomFieldState,
            `${value.fieldName}.dateValue`,
            `${value.fieldName}Error`,
            `${value.fieldLabel}`
          );
        });
    }

    customFields
      .filter((x) => _.toLower(x.fieldType) == "number")
      .forEach((value) => {
        validationService.validateNumberField(
          newCustomFieldState,
          `${value.fieldName}.numericValue`,
          `${value.fieldName}Error`,
          !isScheduledEvent() && value.isRequired,
          `${value.fieldLabel}`
        );
      });

    var customFieldErrorsToUnset = selectedCustomFields
      .filter((x) =>
        !isScheduledEvent()
          ? x.isRequired || _.toLower(x.fieldType) == "number"
          : _.toLower(x.fieldType) == "number"
      )
      .map((field) => `${field.fieldName}Error`);
    isFormValid =
      !validationService.hasError(
        newState,
        "monitoringLocationIdError",
        "nameError",
        isScheduledEvent() ? "dueDateTimeUtcError" : "endDateTimeUtcError",
        "eventTypeIdError",
        ...customFieldErrorsToUnset
      ) &&
      !validationService.hasError(
        newCustomFieldState,
        ...customFieldErrorsToUnset
      );
    //Validate new results
    var resultsValid = true;
    newState.newResults.forEach((result) => {
      validationService.validateRequiredField(
        result,
        `parameterId`,
        `parameterIdError`,
        `Parameter`
      );
      validationService.validateRequiredField(
        result,
        `unitId`,
        `unitIdError`,
        `Unit`
      );
      if (!isScheduledEvent()) {
        validationService.validateRequiredField(
          result,
          `enteredValue`,
          `enteredValueError`,
          `Result`
        );
      }
      resultsValid = !validationService.hasError(
        result,
        "parameterIdError",
        "unitIdError",
        "enteredValueError"
      );
    });
    if (!isFormValid || !resultsValid) {
      setFormState(newState);
      setCustomFieldState(newCustomFieldState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };
  const handleParameterChange = (e, id) => {
    let existingState = { ...formState };
    let resultIndex = existingState.newResults.findIndex((x) => x.id == id);
    let name = "parameterId";
    let value = e.id;
    _.set(existingState.newResults[resultIndex], name, value);
    if (value) {
      existingState = setDefaultUnit(id, value, existingState);
    }

    setFormState(existingState);
    setFormTouched(true);
  };
  const handleInputChange = (e) => {
    let newState = { ...formState };
    let name = e.target.name;
    let value = e.target.type == "checkbox" ? e.target.checked : e.target.value;
    if (name.includes("monitoringLocationId")) {
      const monLoc = monitoringLocations.find((x) => x.id == value);
      if (!isExistingEvent() && !locationTouched) {
        if (monLoc) {
          setDefaultLatitude(monLoc.latitude);
          setDefaultLongitude(monLoc.longitude);
        }
      }
    }

    _.set(newState, name, value);
    setFormState(newState);
  };

  const handleNewResultChange = (e, id) => {
    setFormTouched(true);
    let existingState = { ...formState };
    let resultIndex = existingState.newResults.findIndex((x) => x.id == id);
    let name = e.target.name;
    let value = e.target.value;
    _.set(existingState.newResults[resultIndex], name, value);
    setFormState(existingState);
  };

  const handleResultInputChange = (e) => {
    let newState = { ...resultFormState };
    let name = e.target.name;
    let value = e.target.value;
    _.set(newState, name, value);
    setResultFormState(newState);
  };

  const setDefaultUnit = (id, value, newState) => {
    let index = newState.newResults.findIndex((x) => x.id == id);
    let param = parameters.find((x) => x.id == value);
    var defaultUnit = units.find((x) => x.id == param.defaultUnitId);
    if (defaultUnit) {
      newState.newResults[index].unitId = defaultUnit.id;
    }

    return newState;
  };

  const handleAddParameter = () => {
    let existingResults = [...formState.newResults];
    let highestNumber = _.isEmpty(existingResults)
      ? 0
      : _.orderBy(existingResults, "id").findLast((x) => x.id).id;
    let newNumber = highestNumber + 1;
    existingResults.unshift({
      id: newNumber,
      parameterId: "",
      parameterIdError: "",
      unitId: "",
      unitIdError: "",
      enteredValue: "",
      enteredValueError: "",
      enteredReportingDetectionLimit: "",
      enteredMethodDetectionLimit: "",
      resultTypeId: "",
      sequence: "",
      notes: "",
    });

    setFormState({ ...formState, newResults: existingResults });
  };

  const handleResultRemove = (result, isExistingResult = false) => {
    const resultToRemove = result ?? resultToDelete;
    if (isExistingResult) {
      deleteResultTrigger(resultToRemove.id).then((res) => {
        showAlert("success", "Result deleted.");
        setShowDeleteParamDialog(false);
      });
    } else {
      let existingState = { ...formState };
      var resultIndex = existingState.newResults.findIndex(
        (x) => x.id == resultToRemove.id
      );
      existingState.newResults.splice(resultIndex, 1);
      setFormState(existingState);
      setShowDeleteParamDialog(false);
    }
  };

  const handleParameterDelete = (result) => {
    let newState = { ...formState };
    if (
      newState.newResults.find((x) => x.id == result.id).parameterId &&
      newState.newResults.find((x) => x.id == result.id).enteredValue
    ) {
      setShowDeleteParamDialog(true);
      setResultToDelete({ ...result, isNew: true });
    } else {
      handleResultRemove(result);
    }
  };

  const saveForm = () => {
    if (!validateDataForSave()) {
      return;
    }
    setIsSaving(true);
    var customFieldErrorsToUnset = customFields
      .filter((x) => x.isRequired)
      .map((field) => `${field.fieldName}Error`);
    var customFieldsToUnset = customFields
      .filter(
        (x) =>
          x.parentLookupId != null && x.parentLookupId != formState.eventTypeId
      )
      .map((field) => `${field.fieldName}`);

    let form = validationService.unsetErrors(
      formState,
      "monitoringLocationIdError",
      "nameError",
      "endDateTimeUtcError",
      "eventTypeIdError",
      ...customFieldErrorsToUnset
    );
    form = validationService.unsetFields(form, ...customFieldsToUnset);

    let formToSave = _.cloneDeep(form);
    let eventCustomFieldValues = transformCustomFieldState(
      customFields,
      customFieldState,
      formState.eventTypeId
    );
    formToSave.eventCustomFieldValues = eventCustomFieldValues;
    formToSave.endDateTimeUtc = formToSave.endDateTimeUtc
      ? moment(formToSave.endDateTimeUtc).utc().format()
      : "";
    formToSave.startDateTimeUtc = formToSave.startDateTimeUtc
      ? moment(formToSave.startDateTimeUtc).utc().format()
      : "";

    if (isExistingEvent()) {
      updateTrigger({ id: params.eventId, body: formToSave }).then(
        (res) => {
          setIsSaving(false);
          if (res.data) {
            showAlert("success", "Event saved.");
            setFormStateFromProps(res.data);
          }
        }
      );
    } else {
      let guid = createGuid();
      let form = new FormData();
      var attachmentsToUpload = [];
      if (!_.isEmpty(uploadedFiles)) {
        for (var index = 0; index < uploadedFiles.length; index++) {
          let file = uploadedFiles[index];
          attachmentsToUpload.push({
            name: file.name,
            blob: file.slice(0, file.size, file.type),
          });
          var element = uploadedFiles[index];
          form.append("file", element);
        }
      }
      let dataString = JSON.stringify(formToSave);

      form.append("data", dataString);
      createTrigger(form)
        .then((res) => {
          setIsSaving(false);
          if (res.data) {
            showAlert("success", "Event created.");
            history.push(`/monitoring/${res.data.id}`);
          }
        })
        .catch((err) => {
          if (err.response) {
            setIsSaving(false);
            showAlert("error", err.response.data.message);
          } else {
            localforage.getItem(monitoringEventsStore).then((res) => {
              let values = [...res];
              values.push({
                id: guid,
                ...formToSave,
                notSynced: true,
                monitoringLocationNumber: monitoringLocations.find(
                  (x) => x.id == formToSave.monitoringLocationId
                ).monitoringLocationNumber,
                monitoringLocationName: monitoringLocations.find(
                  (x) => x.id == formToSave.monitoringLocationId
                ).name,
                eventTypeName: eventTypes.find(
                  (x) => x.id == formToSave.eventTypeId
                ).code,
                assetName: monitoringLocations.find(
                  (x) => x.id == formToSave.monitoringLocationId
                ).assetName,
              });
              localforage.setItem(monitoringEventsStore, values);
            });

            localforage.getItem(eventSyncStore).then((res) => {
              let data = res != null ? [...res] : [];
              data.push({
                id: guid,
                type: "monitoring",
                form: { ...formToSave },
                attachments: [...attachmentsToUpload],
              });
              localforage.setItem(eventSyncStore, data).then(() => {
                setIsSaving(false);
                showAlert("success", "Event saved for syncing.");
                history.push(`/monitoring`);
              });
            });
          }
        });
    }
  };

  const handleNavigateTo = (e, action) => {
    if (e && e != null) {
      e.preventDefault();
    }

    let url = `/assets/${params.id}`;
    if (action == "asset") {
      history.push(url);
    }
    if (action == "monitoring") {
      history.push("/monitoring");
    }
  };
  const handleCustomFieldChange = (e) => {
    let newState = { ...customFieldState };
    const name = e.target.name;
    const value = customFields
      .filter((x) => x.fieldType == "Checkbox")
      .map((x) => `${x.fieldName}.booleanValue`)
      .includes(name)
      ? e.target.checked
      : customFields
          .filter((x) => x.fieldType == "MultiSelect")
          .map((x) => `${x.fieldName}.textValue`)
          .includes(name)
      ? e.target.value.join("|")
      : e.target.value;

    _.set(newState, name, value);

    setCustomFieldState(newState);
  };
  const handleShowNotes = (result) => {
    setSelectedResult(result);
    setShowNotes(true);
  };
  const deleteItem = () => {
    deleteTrigger(params.eventId).then((res) => {
      showAlert("success", "Event deleted.");
      setShowDelete(false);
      history.push("/monitoring");
    });
  };
  const handleSaveResult = () => {
    if (!validateResultDataForSave()) {
      return;
    }
    updateResultTrigger({ id: resultFormState.id, dto: resultFormState }).then(
      (res) => {
        setShowEditResult(false);
        setResultFormState({});
        showAlert("success", "Result updated.");
      }
    );
  };

  const isFormLocked = roleMatch([UserRoles.MonitoringViewer]);

  const validateResultDataForSave = () => {
    let newState = { ...resultFormState };
    let isFormValid = false;

    validationService.validateRequiredField(
      newState,
      "parameterId",
      "parameterIdError",
      "Parameter"
    );
    validationService.validateRequiredField(
      newState,
      "enteredValue",
      "enteredValueError",
      "Value"
    );
    validationService.validateRequiredField(
      newState,
      "unitId",
      "unitIdError",
      "Unit"
    );

    isFormValid = !validationService.hasError(
      newState,
      "parameterIdError",
      "enteredValueError",
      "unitIdError"
    );
    if (!isFormValid) {
      setResultFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  const handleTemplateLoad = (templateType) => {
    var parametersToAdd = monitoringLimits.filter(
      (x) => x.monitoringLimitType == templateType
    );
    let existingResults = [...formState.newResults];
    let highestNumber = _.isEmpty(existingResults)
      ? 0
      : _.orderBy(existingResults, "id").findLast((x) => x.id).id;
    let newNumber = highestNumber + 1;
    let paramsToAdd = [];
    parametersToAdd.forEach((param) => {
      paramsToAdd.push({
        id: newNumber,
        parameterId: param.parameterId,
        parameterIdError: "",
        unitId: param.unitId,
        unitIdError: "",
        enteredValue: "",
        enteredValueError: "",
        enteredReportingDetectionLimit: "",
        enteredMethodDetectionLimit: "",
        resultTypeId: "",
        sequence: "",
        notes: "",
      });
      newNumber++;
    });
    existingResults = [...paramsToAdd, ...existingResults];
    setFormState({ ...formState, newResults: existingResults });
    handleClose();
  };

  const resultForm = (
    <>
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Col md={6} width={12}>
              <Form.Group label="Parameter" isRequired>
                <SearchableSelect
                  name={`parameterId`}
                  options={parameters}
                  labelField="name"
                  idField="id"
                  id={"parameterId"}
                  value={resultFormState.parameterId}
                  error={resultFormState.parameterIdError}
                  placeholder={"Search or choose from list"}
                  onChange={(e) => {
                    setResultFormState({
                      ...resultFormState,
                      parameterId: e.id ?? "",
                    });
                  }}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={3} width={12}>
              <Form.Group label="Value" isRequired>
                <Form.Input
                  type="text"
                  onChange={handleResultInputChange}
                  name={`enteredValue`}
                  error={resultFormState.enteredValueError}
                  value={resultFormState.enteredValue}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={3} width={12}>
              <Form.Group label="Unit" isRequired>
                <Form.Select
                  onChange={handleResultInputChange}
                  name={`unitId`}
                  value={resultFormState.unitId}
                  error={resultFormState.unitIdError}
                >
                  <option value={""}></option>
                  {units.map((unit) => (
                    <option value={unit.id} key={unit.id}>
                      {unit.code}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={6} width={12}>
              <Form.Group label="Result Type">
                <Form.Select
                  onChange={handleResultInputChange}
                  name={`resultTypeId`}
                  value={resultFormState.resultTypeId}
                >
                  <option value={""}></option>
                  {resultTypes.map((type) => (
                    <option value={type.id} key={type.id}>
                      {type.code}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={6} width={12}>
              <Form.Group label="Sequence">
                <Form.Input
                  type="text"
                  onChange={handleResultInputChange}
                  name={`sequence`}
                  value={resultFormState.sequence}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={6} width={12}>
              <Form.Group label="Analysis Method">
                <Form.Input
                  type="text"
                  onChange={handleResultInputChange}
                  name={`analysisMethod`}
                  value={resultFormState.analysisMethod}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={3} width={12}>
              <Form.Group label="MDL">
                <Form.Input
                  type="text"
                  onChange={handleResultInputChange}
                  name={`enteredMethodDetectionLimit`}
                  value={resultFormState.enteredMethodDetectionLimit}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={3} width={12}>
              <Form.Group label="RDL">
                <Form.Input
                  type="text"
                  onChange={handleResultInputChange}
                  name={`enteredReportingDetectionLimit`}
                  value={resultFormState.enteredReportingDetectionLimit}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Notes">
                <LynxTextArea
                  onChange={handleResultInputChange}
                  name={`notes`}
                  value={resultFormState.notes}
                ></LynxTextArea>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </div>
    </>
  );

  if (error) {
    return <PageNotFound message="Monitoring Event not found." />;
  } else
    return (
      <Grid>
        <Dimmer active={isLoading} loader>
          <Paper>
            <Grid.Row className="ml-0 mr-0">
              <Grid.Col lg={12} width={12} className="">
                {" "}
                <div className="d-flex">
                  <Typography
                    variant="h3"
                    className="form-header-text"
                    component="div"
                  >
                    {isExistingEvent()
                      ? formState.monitoringEventNumber
                      : isScheduledEvent()
                      ? "Schedule Monitoring Event"
                      : "Add Monitoring Event"}
                  </Typography>
                </div>
              </Grid.Col>
              <Grid.Col lg={12} width={12} className="">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    underline="hover"
                    color="inherit"
                    href="#"
                    onClick={(e) => handleNavigateTo(e, "monitoring")}
                  >
                    Monitoring
                  </Link>
                  <Typography color="text.primary">
                    {isExistingEvent()
                      ? formState.monitoringEventNumber
                      : isScheduledEvent()
                      ? "Schedule Monitoring Event"
                      : "Add Monitoring Event"}
                  </Typography>
                </Breadcrumbs>
              </Grid.Col>
            </Grid.Row>
          </Paper>
          <Grid.Row>
            <Container className="mt-2" maxWidth="xl">
              <Form className="card ">
                <Card.Body className="pt-0 pb-0 pl-0 pr-0">
                  <Grid.Row>
                    <Accordion
                      expanded={detailsExpanded}
                      onChange={() => setDetailsExpanded(!detailsExpanded)}
                      className="w-100"
                    >
                      <AccordionSummary
                        aria-controls="details-content"
                        id="details-header"
                      >
                        <Typography>Details</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid.Row>
                          <Grid.Col md={6} width={12}>
                            <Form.Group isRequired label="Monitoring Location">
                              <Form.Select
                                disabled={isFormLocked}
                                className="monitoringLocationId"
                                name="monitoringLocationId"
                                onChange={handleInputChange}
                                data-id={"1"}
                                value={formState.monitoringLocationId}
                                error={formState.monitoringLocationIdError}
                              >
                                {!_.isEmpty(monitoringLocations) && (
                                  <>
                                    <option value={""}></option>
                                    {monitoringLocations.map((monLoc) => (
                                      <option
                                        value={monLoc.id}
                                        isSelected={
                                          _.toString(
                                            formState.monitoringLocationId
                                          ) == _.toString(monLoc.id)
                                        }
                                        className="mon-loc-option"
                                        key={monLoc.id}
                                      >
                                        {monLoc.name}
                                      </option>
                                    ))}
                                  </>
                                )}
                              </Form.Select>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col md={6} width={12}>
                            <Form.Group label="Sample ID" isRequired>
                              <Form.Input
                                disabled={isFormLocked}
                                type="text"
                                name="name"
                                onChange={handleInputChange}
                                value={formState.name}
                                error={formState.nameError}
                              ></Form.Input>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col md={6} width={12}>
                            <Form.Group isRequired label="Event Type">
                              <Form.Select
                                disabled={isFormLocked}
                                name="eventTypeId"
                                onChange={handleInputChange}
                                value={formState.eventTypeId}
                                error={formState.eventTypeIdError}
                              >
                                <option value={""}></option>
                                {eventTypes.map((lookup) => (
                                  <option value={lookup.id} key={lookup.id}>
                                    {lookup.code}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col md={6} width={12}></Grid.Col>
                          <Grid.Col md={6} width={12}>
                            <Form.Group
                              label="Due Date"
                              isRequired={isScheduledEvent()}
                            >
                              <Form.Input
                                disabled={isFormLocked}
                                type="date"
                                value={formState.dueDateTimeUtc}
                                name="dueDateTimeUtc"
                                onChange={handleInputChange}
                                error={formState.dueDateTimeUtcError}
                              />
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col md={6} width={12}>
                            <Form.Group label="Assigned To">
                              <SearchableSelect
                                disabled={isFormLocked}
                                name="assignedToUserId"
                                options={users}
                                labelField="fullName"
                                id="assignedToUserId"
                                idField="id"
                                value={formState.assignedToUserId}
                                placeholder={"Search or choose from list"}
                                onChange={(newValue) => {
                                  setFormState((existing) => {
                                    return {
                                      ...existing,
                                      assignedToUserId: newValue.id,
                                    };
                                  });
                                }}
                              />
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col md={6} width={12}>
                            <Form.Group label="Start Date">
                              <Form.Input
                                disabled={isFormLocked}
                                type="datetime-local"
                                value={formState.startDateTimeUtc}
                                name="startDateTimeUtc"
                                onChange={handleInputChange}
                              />
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col md={6} width={12}>
                            <Form.Group
                              isRequired={!isScheduledEvent()}
                              label="End Date"
                            >
                              <Form.Input
                                disabled={isFormLocked}
                                type="datetime-local"
                                value={formState.endDateTimeUtc}
                                name="endDateTimeUtc"
                                onChange={handleInputChange}
                                error={formState.endDateTimeUtcError}
                              />
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col md={12} width={12}>
                            <Form.Group label="Description">
                              <LynxTextArea
                                disabled={isFormLocked}
                                name="description"
                                onChange={handleInputChange}
                                value={formState.description}
                              ></LynxTextArea>
                            </Form.Group>
                          </Grid.Col>
                          {account.hasMonitoringConfidentialAccess && (
                            <Grid.Col md={12} width={12}>
                              <Form.Group label="">
                                <Form.Checkbox
                                  disabled={isFormLocked}
                                  label="Confidential?"
                                  name="isConfidential"
                                  checked={formState.isConfidential}
                                  onChange={handleInputChange}
                                />
                              </Form.Group>
                            </Grid.Col>
                          )}
                        </Grid.Row>
                      </AccordionDetails>
                    </Accordion>
                    {!_.isEmpty(selectedCustomFields) && (
                      <Accordion
                        expanded={customFieldsExpanded}
                        onChange={() =>
                          setCustomFieldsExpanded(!customFieldsExpanded)
                        }
                        className="w-100"
                      >
                        <AccordionSummary
                          aria-controls="custom-fields-content"
                          id="custom-fields-header"
                        >
                          <Typography>Additional Fields</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid.Row>
                            {selectedCustomFields
                              .sort((a, b) =>
                                a.position > b.position ? 1 : -1
                              )
                              .filter((x) => !x.isDeleted)
                              .map((field) => (
                                <CustomField
                                  disabled={isFormLocked}
                                  customField={field}
                                  key={field.position}
                                  isRequired={
                                    field.isRequired && !isScheduledEvent()
                                  }
                                  handleInputChange={handleCustomFieldChange}
                                  formState={customFieldState}
                                ></CustomField>
                              ))}
                          </Grid.Row>
                        </AccordionDetails>
                      </Accordion>
                    )}
                    <Accordion
                      className="w-100"
                      expanded={resultsExpanded}
                      onChange={() => setResultsExpanded(!resultsExpanded)}
                    >
                      <AccordionSummary
                        aria-controls="results-content"
                        id="results-header"
                      >
                        <Typography>Results</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid.Row className=" d-flex flex-wrap-reverse">
                          <Grid.Col
                            xl={2}
                            lg={5}
                            md={5}
                            width={12}
                            className="mb-4  "
                          >
                            {!isFormLocked && (
                              <>
                                <Fab
                                  className="ml-2"
                                  size="small"
                                  color="primary"
                                  aria-label="add"
                                  id="addResultBtn"
                                  onClick={handleAddParameter}
                                >
                                  <AddIcon />
                                </Fab>
                                <Typography
                                  variant="h6"
                                  className="ml-3"
                                  component={"b"}
                                >
                                  Add Result
                                </Typography>
                              </>
                            )}
                          </Grid.Col>
                          <Grid.Col xl={10} lg={7} md={7} width={12}>
                            {!_.isEmpty(monitoringLimits) && (
                              <>
                                <Button
                                  id="template-button"
                                  className="mb-4"
                                  variant="outlined"
                                  aria-controls={
                                    templateOpen ? "template-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={
                                    templateOpen ? "true" : undefined
                                  }
                                  onClick={handleClick}
                                >
                                  Load Parameters From Template
                                </Button>
                                <Menu
                                  id="template-menu"
                                  anchorEl={anchorEl}
                                  open={templateOpen}
                                  onClose={handleClose}
                                  MenuListProps={{
                                    "aria-labelledby": "template-button",
                                  }}
                                >
                                  {_.orderBy(
                                    _.uniq(
                                      monitoringLimits.map(
                                        (x) => x.monitoringLimitType
                                      )
                                    )
                                  ).map((x) => (
                                    <MenuItem
                                      key={x}
                                      onClick={() => {
                                        handleTemplateLoad(x);
                                      }}
                                    >
                                      {x}
                                    </MenuItem>
                                  ))}
                                </Menu>
                              </>
                            )}
                          </Grid.Col>
                        </Grid.Row>

                        {formState.newResults.map((result, i) => (
                          <div className="pt-2 result-group" key={i}>
                            <Grid.Row>
                              <React.Fragment key={i}>
                                <Grid.Col
                                  className="align-self-center"
                                  xl={1}
                                  lg={1}
                                  md={1}
                                  width={12}
                                >
                                  <Tooltip title="Notes">
                                    <IconButton
                                      id="notesBtn"
                                      onClick={() => {
                                        handleShowNotes(result);
                                      }}
                                    >
                                      <TextSnippetIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Remove">
                                    <IconButton
                                      onClick={() => {
                                        handleParameterDelete(result);
                                      }}
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid.Col>
                                <Grid.Col xl={3} lg={5} md={5} width={12}>
                                  <Form.Group
                                    isRequired
                                    label={
                                      isExistingEvent() &&
                                      (formState.newResults.find(
                                        (x) => x.id == result.id
                                      ).isNonCompliant == false ||
                                        formState.newResults.find(
                                          (x) => x.id == result.id
                                        ).isNonCompliant == true) ? (
                                        <>
                                          <LightTooltip
                                            title={
                                              <div
                                                style={{ fontSize: 15 }}
                                              >{`Limit: ${
                                                formState.newResults.find(
                                                  (x) => x.id == result.id
                                                ).limitValue
                                              } ${
                                                formState.newResults.find(
                                                  (x) => x.id == result.id
                                                ).limitUnitName
                                              }`}</div>
                                            }
                                          >
                                            <i
                                              className={`fe fe-circle ${getResultComplianceColor(
                                                formState.newResults.find(
                                                  (x) => x.id == result.id
                                                ).isNonCompliant
                                              )}`}
                                            />
                                          </LightTooltip>
                                          &nbsp;{" "}
                                          <span
                                            style={{
                                              fontWeight: "600",
                                              fontSize: "0.875rem",
                                            }}
                                          >
                                            Parameter
                                          </span>
                                          <span className="form-required">
                                            *
                                          </span>
                                        </>
                                      ) : (
                                        "Parameter"
                                      )
                                    }
                                  >
                                    <SearchableSelect
                                      name={`parameterId`}
                                      options={parameters}
                                      labelField="name"
                                      idField="id"
                                      id="parameterId"
                                      value={
                                        formState.newResults.find(
                                          (x) => x.id == result.id
                                        ).parameterId
                                      }
                                      error={
                                        formState.newResults.find(
                                          (x) => x.id == result.id
                                        ).parameterIdError
                                      }
                                      placeholder={"Search or choose from list"}
                                      onChange={(e) => {
                                        handleParameterChange(e, result.id);
                                      }}
                                    />
                                  </Form.Group>
                                </Grid.Col>
                                <Grid.Col xl={2} lg={3} md={3} width={12}>
                                  <Form.Group
                                    label="Result"
                                    isRequired={!isScheduledEvent()}
                                  >
                                    <Form.Input
                                      type="text"
                                      name={`enteredValue`}
                                      onChange={(e) =>
                                        handleNewResultChange(e, result.id)
                                      }
                                      value={
                                        formState.newResults.find(
                                          (x) => x.id == result.id
                                        ).enteredValue
                                      }
                                      error={
                                        formState.newResults.find(
                                          (x) => x.id == result.id
                                        ).enteredValueError
                                      }
                                    ></Form.Input>
                                  </Form.Group>
                                </Grid.Col>
                                <Grid.Col xl={2} lg={3} md={3} width={12}>
                                  <Form.Group label="Unit" isRequired={true}>
                                    <Form.Select
                                      name={`unitId`}
                                      onChange={(e) =>
                                        handleNewResultChange(e, result.id)
                                      }
                                      value={
                                        formState.newResults.find(
                                          (x) => x.id == result.id
                                        ).unitId
                                      }
                                      error={
                                        formState.newResults.find(
                                          (x) => x.id == result.id
                                        ).unitIdError
                                      }
                                    >
                                      <option value={""}></option>
                                      {units.map((unit) => (
                                        <option value={unit.id} key={unit.id}>
                                          {unit.code}
                                        </option>
                                      ))}
                                    </Form.Select>
                                  </Form.Group>
                                </Grid.Col>

                                <Grid.Col xl={2} lg={6} md={6} width={12}>
                                  <Form.Group label="Result Type">
                                    <Form.Select
                                      onChange={(e) =>
                                        handleNewResultChange(e, result.id)
                                      }
                                      name={`resultTypeId`}
                                      value={
                                        formState.newResults.find(
                                          (x) => x.id == result.id
                                        ).resultTypeId
                                      }
                                    >
                                      <option value={""}></option>
                                      {resultTypes.map((type) => (
                                        <option value={type.id} key={type.id}>
                                          {type.code}
                                        </option>
                                      ))}
                                    </Form.Select>
                                  </Form.Group>
                                </Grid.Col>
                                <Grid.Col xl={2} lg={6} md={6} width={12}>
                                  <Form.Group label="Sequence">
                                    <Form.Input
                                      type="text"
                                      onChange={(e) =>
                                        handleNewResultChange(e, result.id)
                                      }
                                      name={`sequence`}
                                      value={
                                        formState.newResults.find(
                                          (x) => x.id == result.id
                                        ).sequence
                                      }
                                    ></Form.Input>
                                  </Form.Group>
                                </Grid.Col>
                              </React.Fragment>
                            </Grid.Row>
                          </div>
                        ))}

                        {isExistingEvent() && (
                          <>
                            <Typography variant="h6" className="mt-1">
                              Existing Results
                            </Typography>
                            <div style={{ height: "100%" }} className="mt-1">
                              {!_.isEmpty(columns) && (
                                <LynxDataGrid
                                  useQuery={useGetMonitoringResultsQuery}
                                  columns={columns}
                                  onlyShowTable
                                  autoHeight
                                  useQueryParams={{
                                    monitoringEventId:
                                      params.eventId,
                                  }}
                                  entityType={EntityTypes.MonitoringResult}
                                  localStorageName={`monitoringResultEvent_${params.eventId}`}
                                />
                              )}
                            </div>
                          </>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      className="w-100"
                      expanded={attachmentsExpanded}
                      onChange={() =>
                        setAttachmentsExpanded(!attachmentsExpanded)
                      }
                    >
                      <AccordionSummary
                        aria-controls="attachments-content"
                        id="attachments-header"
                      >
                        <Typography>Attachments</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <AttachmentViewer
                          isLocked={isFormLocked}
                          importAttachments={
                            isExistingEvent() ? getImportAttachments() : null
                          }
                          isExistingEntity={isExistingEvent()}
                          entityType={EntityTypes.MonitoringEvent}
                          entityId={params.eventId}
                          cardClass="mb-0"
                          handleSetUploadedFiles={(files) => {
                            setUploadedFiles(files);
                          }}
                          rowHeight={200}
                          cols={6}
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      className="w-100"
                      expanded={locationExpanded}
                      onChange={() => setLocationExpanded(!locationExpanded)}
                    >
                      <AccordionSummary
                        aria-controls="location-content"
                        id="location-header"
                      >
                        <Typography>Location</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid.Row>
                          <LocationSelectionControl
                            disabled={isFormLocked}
                            defaultLatitude={defaultLatitude}
                            defaultLongitude={defaultLongitude}
                            handleLatLongChange={handleLatLongChange}
                            handleLocationTouched={() =>
                              setLocationTouched(true)
                            }
                          />
                        </Grid.Row>
                      </AccordionDetails>
                    </Accordion>
                  </Grid.Row>
                </Card.Body>
                <Card.Footer>
                  {roleMatch([UserRoles.MonitoringAdministrator]) &&
                    isExistingEvent() && (
                      <Button
                        variant="contained"
                        color="error"
                        className="float-left"
                        onClick={() => setShowDelete(true)}
                      >
                        Delete
                      </Button>
                    )}
                  {!isFormLocked && (
                    <Button
                      variant="contained"
                      className="float-right"
                      onClick={saveForm}
                    >
                      Save
                    </Button>
                  )}
                </Card.Footer>
              </Form>
            </Container>
          </Grid.Row>
        </Dimmer>
        {showDelete && (
          <LynxDialog
            open={showDelete}
            handleClose={() => setShowDelete(false)}
            handleDelete={deleteItem}
            title={`Delete Event?`}
            description={"All associated results will be deleted."}
          />
        )}
        {showDeleteParamDialog && (
          <LynxDialog
            open={showDeleteParamDialog}
            handleClose={() => {
              setShowDeleteParamDialog(false);
              setResultToDelete(null);
            }}
            handleDelete={() => {
              handleResultRemove(null, resultToDelete.isNew ? false : true);
            }}
            title={`Remove Result?`}
            description={
              "Are you sure you want to remove this result from the monitoring event?"
            }
          />
        )}
        {isOffline && !isOfflineReady && (
          <LynxDialog
            open={isOffline && !isOfflineReady}
            title={`Unable to create event offline`}
            description={
              "Return online and press the sync button in the top right corner."
            }
            handleClose={(e) => {
              handleNavigateTo(null, "monitoring");
            }}
          />
        )}
        {showNotes && selectedResult && (
          <LynxDialog
            maxWidth={"md"}
            open={showNotes}
            title={`Additional Details`}
            fullWidth
            dialogContent={
              <Grid>
                <Grid.Row>
                  <Grid.Col md={6} width={12}>
                    <Form.Group label="MDL">
                      <Form.Input
                        type="text"
                        onChange={(e) =>
                          handleNewResultChange(e, selectedResult.id)
                        }
                        name={`enteredMethodDetectionLimit`}
                        value={
                          formState.newResults.find(
                            (x) => x.id == selectedResult.id
                          ).enteredMethodDetectionLimit
                        }
                      ></Form.Input>
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col md={6} width={12}>
                    <Form.Group label="RDL">
                      <Form.Input
                        type="text"
                        onChange={(e) =>
                          handleNewResultChange(e, selectedResult.id)
                        }
                        name={`enteredReportingDetectionLimit`}
                        value={
                          formState.newResults.find(
                            (x) => x.id == selectedResult.id
                          ).enteredReportingDetectionLimit
                        }
                      ></Form.Input>
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col md={6} width={12}>
                    <Form.Group label="Analysis Method">
                      <Form.Input
                        type="text"
                        onChange={(e) =>
                          handleNewResultChange(e, selectedResult.id)
                        }
                        name={`analysisMethod`}
                        value={
                          formState.newResults.find(
                            (x) => x.id == selectedResult.id
                          ).analysisMethod
                        }
                      ></Form.Input>
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col md={6} width={12}>
                    <Form.Group label="Notes">
                      <LynxTextArea
                        onChange={(e) =>
                          handleNewResultChange(e, selectedResult.id)
                        }
                        name={`notes`}
                        value={
                          formState.newResults.find(
                            (x) => x.id == selectedResult.id
                          ).notes
                        }
                      ></LynxTextArea>
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              </Grid>
            }
            handleConfirm={(e) => {
              setShowNotes(false);
              setSelectedResult(null);
            }}
          />
        )}
        {showEditResult && !_.isEmpty(resultFormState) && (
          <LynxDialog
            dividers
            maxWidth={"md"}
            open={showEditResult}
            title={`Edit Result`}
            fullWidth
            dialogContent={resultForm}
            handleClose={() => {
              setShowEditResult(false);
              setResultFormState({});
            }}
            handleSave={handleSaveResult}
          />
        )}
        {isSaving && (
          <LynxDialog
            open={isSaving}
            title={`Saving Monitoring Event. Do not close the window.`}
            description={
              <>
                <div className="d-flex align-items-center justify-content-center mt-4">
                  <CircularProgress />
                </div>
              </>
            }
          />
        )}
      </Grid>
    );
}

export const getResultComplianceColor = (isCompliant) => {
  switch (isCompliant) {
    case false:
      return "text-success";
    case true:
      return "text-danger";
    default:
      return "text-warning";
  }
};
