import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import _ from "lodash";
import { BootstrapInput } from "../bootstrap-inputs";

const SORTED_DEFAULT = true;

export default function SingleSelect(props) {
  return (
    <div>
      <FormControl sx={{ width: "100%" }} variant="standard">
        <Select
          disabled={props.disabled}
          defaultValue={props.defaultValue}
          value={props.value}
          displayEmpty
          name={props.name}
          onChange={(e) => props.onChange(e)}
          input={<BootstrapInput error={props.error ? true : false} />}
        >
          <MenuItem value={""} className="single-select-option">
            &nbsp;
          </MenuItem>
          {(props.isSorted ?? SORTED_DEFAULT
            ? _.sortBy(props.dropdownValues, [
                (value) =>
                  props.labelFunction
                    ? props.labelFunction(value)
                    : props.label
                    ? value[props.label].toLowerCase()
                    : value,
              ])
            : props.dropdownValues
          ).map((value) => {
            return (
              <MenuItem
                key={props.id ? value[props.id] : value}
                value={props.id ? value[props.id] : value}
                className="single-select-option"
              >
                {props.labelFunction
                  ? props.labelFunction(value)
                  : props.label
                  ? value[props.label]
                  : value}
              </MenuItem>
            );
          })}
        </Select>
        <span style={{ display: "inline" }} className="invalid-feedback">
          {props.error}
        </span>
      </FormControl>
    </div>
  );
}
