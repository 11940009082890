import { Box, Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import { MultiSelect } from "components/form-controls/multi-select";
import { Card, Dimmer, Form, Grid } from "components/lynx-components";
import PageNotFound from "components/PageNotFound";
import useAlert from "hooks/useAlert";
import _ from "lodash";
import lynxColors from "modules/lynxColors";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { queryRagModelAi } from "services/admin";
import { useGetDocumentsQuery } from "services/rtkApi/endpoints/documents";
import { RagPromptQueryResponseDto, RootState } from "types";
import DocumentSourceChip from "./document-source-chip";
interface FormState {
  prompt?: string;
  referenceEntityAttachmentIds: number[];
  includeEvents?: boolean;
  includeDocuments?: boolean;
  referenceDataRecordLimit: number;
  supplementalDataRecordLimit: number;
}
const initialForm: FormState = {
  prompt: "",
  includeEvents: true,
  includeDocuments: false,
  referenceEntityAttachmentIds: [],
  referenceDataRecordLimit: 10,
  supplementalDataRecordLimit: 10,
};
const AiPlayground: FC<{}> = () => {
  const [formState, setFormState] = useState(initialForm);
  const organization = useSelector((state: RootState) => state.organization);
  const { showAlert } = useAlert();
  const [response, setResponse] = useState<RagPromptQueryResponseDto | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    let newState = { ...formState };
    let { name } = e.target;
    const value =
      e.target.type == "checkbox" ? e.target.checked : e.target.value;
    _.set(newState, name, value);
    setFormState(newState);
  };

  const { data } = useGetDocumentsQuery({ pageSize: 1000 });

  const handleSendResponse = () => {
    var requestBody = { ...formState };
    setIsLoading(true);
    requestBody.referenceEntityAttachmentIds =
      formState.referenceEntityAttachmentIds.map(
        (a: any) => a.entityAttachmentId
      );
    queryRagModelAi(requestBody)
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        showAlert("error", "Error running query: " + err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  if (!organization.featureFlags?.showAiFeatures) return <PageNotFound />;
  else
    return (
      <Container>
        <Card className="mt-5">
          <Card.Header>
            <Card.Title>Lynx AI Assistant</Card.Title>
          </Card.Header>
          <Card.Body className="pt-2">
            <Grid>
              <Grid.Row className="mb-2">
                <Typography variant="h6">Request</Typography>
              </Grid.Row>
              <Box
                sx={{
                  border: `1px solid ${lynxColors.gray}`,
                  padding: "0.5rem",
                }}
              >
                <Grid.Row>
                  <Grid.Col md={6} width={12}>
                    <Form.Group label="">
                      <Form.Checkbox
                        label="Query events?"
                        name={`includeEvents`}
                        onChange={handleInputChange}
                        checked={formState.includeEvents}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col md={6} width={12}>
                    <Form.Group label="">
                      <Form.Checkbox
                        label="Query documents?"
                        name={`includeDocuments`}
                        onChange={handleInputChange}
                        disabled={
                          formState.referenceEntityAttachmentIds.length > 0
                        }
                        checked={formState.includeDocuments}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col md={6} width={12}>
                    <Form.Group label="Reference data record limit" isRequired>
                      <Form.Input
                        type="number"
                        name={`referenceDataRecordLimit`}
                        onChange={handleInputChange}
                        value={formState.referenceDataRecordLimit}
                      ></Form.Input>
                    </Form.Group>
                  </Grid.Col>{" "}
                  <Grid.Col md={6} width={12}>
                    <Form.Group
                      label="Supplemental data record limit"
                      isRequired
                    >
                      <Form.Input
                        type="number"
                        name={`supplementalDataRecordLimit`}
                        onChange={handleInputChange}
                        value={formState.supplementalDataRecordLimit}
                      ></Form.Input>
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={12}>
                    <Form.Group label="Reference documents">
                      <MultiSelect
                        name="referenceEntityAttachmentIds"
                        id="referenceEntityAttachmentIds"
                        onChange={(e: any) => {
                          setFormState({
                            ...formState,
                            referenceEntityAttachmentIds: e.target.value,
                            includeDocuments:
                              e.target.value.length > 0
                                ? false
                                : formState.includeDocuments,
                          });
                        }}
                        value={formState.referenceEntityAttachmentIds}
                        dropdownValues={data && data.data ? data.data : []}
                        key="id"
                        labelFunction={(value: any) => value.name}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={12}>
                    <Form.Group label="Prompt" className="mb-0">
                      <LynxTextArea
                        name="prompt"
                        onChange={handleInputChange}
                        value={formState.prompt}
                      ></LynxTextArea>
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col>
                    <Typography variant="body2" className="">
                      <i>
                        This AI-powered tool provides responses based on indexed
                        documents and data within the system. While we strive
                        for accuracy, the information generated may not always
                        be complete, current, or error-free. Please verify
                        critical information before making decisions.
                      </i>
                    </Typography>
                  </Grid.Col>
                  <Grid.Col width={12}>
                    <Button
                      disabled={_.isEmpty(formState.prompt)}
                      variant="contained"
                      onClick={handleSendResponse}
                      className="float-right mb-2"
                    >
                      Send
                    </Button>
                  </Grid.Col>
                </Grid.Row>
              </Box>
              <Grid.Row className="mb-2 mt-5">
                <Typography variant="h6">Response</Typography>
              </Grid.Row>{" "}
              <Dimmer active={isLoading} loader>
                <Box
                  sx={{
                    border: `1px solid ${lynxColors.gray}`,
                    padding: "0.5rem",
                    minHeight: "100px",
                  }}
                >
                  <Grid.Row>
                    <Grid.Col width={12}>
                      <Form.Group label="Answer">
                        <LynxTextArea
                          autoResize
                          value={response?.answer}
                        ></LynxTextArea>
                      </Form.Group>
                    </Grid.Col>
                    {response && response?.documentSources && (
                      <Grid.Col width={12}>
                        <Form.Group label="Sources">
                          {_.orderBy(
                            _.uniqBy(response?.documentSources, "entityNumber"),
                            ["type", "entityNumber"]
                          ).map((source) => (
                            <DocumentSourceChip
                              className="mr-2 mb-2"
                              source={source}
                            />
                          ))}
                        </Form.Group>
                      </Grid.Col>
                    )}
                  </Grid.Row>
                </Box>
              </Dimmer>
            </Grid>
          </Card.Body>
        </Card>
      </Container>
    );
};

export default AiPlayground;
