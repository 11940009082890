import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { apiUrl } from "../modules/store";
import { LocalStorageKeys } from "../types/enums";
import _ from "lodash";
const TIMEOUT_TIME_MS = 3000;
const onlinePollingInterval = 10000;
const onlineStatusManager = {
  status: true, // Default to online
  setStatus: (newStatus) => {
    onlineStatusManager.status = newStatus;
  },
  getStatus: () => {
    return onlineStatusManager.status;
  },
};
const timeout = (time, promise) => {
  return new Promise(function (resolve, reject) {
    setTimeout(() => {
      reject(new Error("Request timed out."));
    }, time);
    promise.then(resolve, reject);
  });
};

const checkOnlineStatus = async () => {
  const controller = new AbortController();
  const { signal } = controller;
  // If the browser has no network connection return offline

  if (!navigator.onLine) return navigator.onLine;
  var timeoutTime = TIMEOUT_TIME_MS;

  var offlineDisabledDateTime = localStorage.getItem(
    LocalStorageKeys.DisableOfflineDateTime
  );
  if (offlineDisabledDateTime) {
    var disabledDateAdjusted = new Date(
      moment(offlineDisabledDateTime).utc().add(5, "m").format()
    );
    var currentDate = new Date(moment.utc().format());
    if (disabledDateAdjusted > currentDate) {
      return true;
    }
  }

  var offlineTimeout = localStorage.getItem(LocalStorageKeys.OfflineTimeout);
  if (offlineTimeout && _.isFinite(_.toNumber(offlineTimeout))) {
    timeoutTime = _.toNumber(offlineTimeout);
  }

  // Retry configuration
  const maxRetries = 3;
  let retryCount = 0;

  while (retryCount < maxRetries) {
    try {
      await timeout(
        timeoutTime,
        fetch(`${apiUrl}/status`, {
          method: "GET",
          signal,
        })
      );
      return true; // Successfully connected
    } catch (error) {
      console.warn(`Attempt ${retryCount + 1} failed:`, error);
      retryCount += 1;

      // Abort the request after a failure
      controller.abort();

      // Delay before retrying
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }
  return false;
};

const OnlineStatusContext = React.createContext(true);

export const OnlineStatusProvider = ({ children }) => {
  const [onlineStatus, setOnlineStatus] = useState(true);

  const checkStatus = async () => {
    var offlineDisabled = localStorage.getItem(
      LocalStorageKeys.DisableOfflineMode
    );
    if (offlineDisabled == "false" || !offlineDisabled) {
      const online = await checkOnlineStatus();
      setOnlineStatus(online);
      onlineStatusManager.setStatus(online);
    }
  };

  useEffect(() => {
    window.addEventListener("offline", () => {
      var offlineDisabled = localStorage.getItem(
        LocalStorageKeys.DisableOfflineMode
      );
      if (offlineDisabled == "false") {
        setOnlineStatus(false);
        onlineStatusManager.setStatus(false);
      }
    });
    checkStatus();
    // Add polling incase of slow connection
    const id = setInterval(() => {
      checkStatus();
    }, onlinePollingInterval);

    return () => {
      window.removeEventListener("offline", () => {
        setOnlineStatus(false);
        onlineStatusManager.setStatus(false);
      });

      clearInterval(id);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>
      {children}
    </OnlineStatusContext.Provider>
  );
};

export const useOnlineStatus = () => {
  const store = useContext(OnlineStatusContext);
  return store;
};
export const getOnlineStatus = () => {
  return onlineStatusManager.getStatus();
};
export default useOnlineStatus;
