import LinkIcon from "@mui/icons-material/Link";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Grid } from "components/lynx-components";
import useAlert from "hooks/useAlert";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { InspectionEventDto } from "types";
import { dateUtil } from "../../../services/date-util";
import { getStatusChip } from "./inspection-form";
import { getInspection } from "services/inspection-event-service";

interface Props {
  inspection: InspectionEventDto;
  inspectionEventId: number;
  handleClose: () => void;
}

const InspectionQuickView: FC<Props> = (props) => {
  const [inspection, setInspection] = useState<InspectionEventDto>(
    props.inspection
  );
  const history = useHistory();
  const { showAlert } = useAlert();

  useEffect(() => {
    if (props.inspectionEventId > 0) {
      getInspection(props.inspectionEventId).then((res) => {
        setInspection(res.data);
      });
    }
  }, [props.inspection, props.inspectionEventId]);

  const handleEventNumberClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (inspection) {
      history.push(`/inspections/${inspection.id}`);
    }
  };

  const handleLinkClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    navigator.clipboard.writeText(
      `${window.origin}/inspections/${inspection?.id}`
    );
    showAlert("success", "Link copied!");
  };
  return (
    <>
      <DialogContent
        sx={{ padding: "10px 12px", minWidth: "550px", maxWidth: "700px" }}
      >
        <Grid>
          <Grid.Row>
            <Grid.Col width={10}>
              <div className="d-flex flex-wrap align-items-center mb-1">
                <Link
                  href="#"
                  onClick={handleEventNumberClick}
                  underline="hover"
                  className="mr-2"
                >
                  <Typography variant="h6" component="span">
                    {inspection?.inspectionNumber} -{" "}
                    {inspection?.inspectionFormName}
                  </Typography>
                </Link>

                <div>{getStatusChip(inspection.status)}</div>
              </div>
            </Grid.Col>
            <Grid.Col width={2}>
              <div className="d-flex flex-wrap align-items-center mb-1">
                <div className="ml-auto">
                  <Tooltip title="Copy link">
                    <IconButton onClick={handleLinkClick}>
                      <LinkIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col md={6} width={12}>
              {inspection?.inspectionDate && (
                <Typography variant="subtitle2">
                  Inspection Date:&nbsp;
                  {dateUtil.convertDateOnlyToLocal(inspection.inspectionDate)}
                </Typography>
              )}
            </Grid.Col>
            <Grid.Col md={6} width={12}>
              <Typography variant="subtitle2">
                {inspection?.assetName}
              </Typography>
            </Grid.Col>
          </Grid.Row>

          <Divider className="mt-2 mb-2" />
          <Grid.Row className="mt-2">
            {inspection?.assignedToUserFullName && (
              <Grid.Col md={6} width={12}>
                <Typography variant="body1">
                  <b>Assigned To: </b>
                  {inspection.assignedToUserFullName}
                </Typography>
              </Grid.Col>
            )}
          </Grid.Row>
          {inspection?.description && (
            <Grid.Row className="mt-2">
              <Grid.Col md={12} width={12}>
                <Typography variant="body1">
                  <b>Description: </b>
                  {inspection.description}
                </Typography>
              </Grid.Col>
            </Grid.Row>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Close</Button>
      </DialogActions>
    </>
  );
};

export default InspectionQuickView;
