import { loggedIn } from "../actions/auth";

export class UrlService {
  isPublicScreen() {
    return (
      document.location.pathname
        .toLowerCase()
        .indexOf("/privacy".toLowerCase()) >= 0 ||
      document.location.pathname
        .toLowerCase()
        .indexOf("/authorize".toLowerCase()) >= 0 ||
      (document.location.pathname == "/" && !loggedIn()) ||
      document.location.pathname
        .toLowerCase()
        .indexOf("/forgot-password".toLowerCase()) >= 0 ||
      document.location.pathname
        .toLowerCase()
        .indexOf("/reset-forgot-password".toLowerCase()) >= 0 ||
      document.location.pathname
        .toLowerCase()
        .indexOf("/register".toLowerCase()) >= 0 ||
      document.location.pathname
        .toLowerCase()
        .indexOf("/community-portal".toLowerCase()) >= 0 ||
      document.location.pathname
        .toLowerCase()
        .indexOf("/investor".toLowerCase()) >= 0 ||
      document.location.pathname
        .toLowerCase()
        .indexOf("/wyloo".toLowerCase()) >= 0 ||
      document.location.pathname == "/"
    );
  }

  isAdminScreen() {
    return (
      document.location.pathname
        .toLowerCase()
        .indexOf("/admin".toLowerCase()) >= 0
    );
  }
}

export const urlService = new UrlService();
