import { apiService } from "../apiService";
import queryString from "query-string";
import { RtkTagTypes } from "types/enums";

const BASE_ROUTE = "/attachments";

const attachmentEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    addEntityAttachments: builder.mutation({
      query: ({
        entityType,
        entityId,
        attachments,
      }: {
        entityType: string;
        entityId: string;
        attachments: File[];
      }) => {
        let form = new FormData();

        for (var index = 0; index < attachments.length; index++) {
          var element = attachments[index];
          form.append("file", element);
        }
        return {
          url: `${BASE_ROUTE}/upload?entityType=${entityType}&entityId=${entityId}`,
          method: "POST",
          body: form,
        };
      },
      invalidatesTags: [RtkTagTypes.GetAttachments],
    }),
    getEntityAttachments: builder.query({
      query: ({
        entityType,
        entityId,
      }: {
        entityType: string;
        entityId: string;
      }) => `${BASE_ROUTE}?entityType=${entityType}&entityId=${entityId}`,
      providesTags: [RtkTagTypes.GetAttachments],
    }),
    deleteEntityAttachment: builder.mutation({
      query: (id: string) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetAttachments],
    }),
    downloadAttachment: builder.query({
      query: (id: string) => ({
        url: `${BASE_ROUTE}/${id}/download`,
        method: "GET",
        responseHandler: (response: any) => response.blob(), // Ensures response is handled as a blob
      }),
    }),
    downloadThumbnail: builder.query({
      query: (id: string) => ({
        url: `${BASE_ROUTE}/${id}/thumbnail`,
        method: "GET",
        responseHandler: (response: any) => response.blob(), // Ensures response is handled as a blob
      }),
    }),
    updateAttachmentDescription: builder.mutation({
      query: ({ id, dto }: { id: string; dto: any }) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "PUT",
        body: dto,
      }),
      invalidatesTags: [RtkTagTypes.GetAttachments],
    }),
  }),
});

export const {
  useAddEntityAttachmentsMutation,
  useGetEntityAttachmentsQuery,
  useLazyGetEntityAttachmentsQuery,
  useDeleteEntityAttachmentMutation,
  useLazyDownloadAttachmentQuery,
  useLazyDownloadThumbnailQuery,
  useUpdateAttachmentDescriptionMutation,
} = attachmentEndpoint;
