import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import styled from "styled-components";
import { loadAccount } from "../actions/account";
import { loginUser } from "../actions/auth";
import LynxImage from "../images/Lynx-brandmark-long-hor-colour-rgb.svg";
import colors from "../modules/colors";
import { getMaintenanceMessage } from "./../services/settings";
import _ from "lodash";
import { MainFooter } from "./../components/dashboards/main-footer";
import useOnlineStatus from "./../hooks/useOnlineStatus";
import "./login.css";
import queryString from "query-string";
import { loadUserOrg } from "../actions/organization";
import useAlert from "hooks/useAlert";

const LoginPanel = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  background-color: white;
  border: 1px ${colors.buttonBorderColor} solid;
  padding: 30px;
`;

const LoginTitle = styled.h3`
  font-size: 24px;
  font-weight: 410;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: center;
  color: black;
`;

const LoginInput = styled.input`
  border: 1px ${(props) => (props.error ? "red" : colors.buttonBorderColor)}
    solid;
  display: block;
  margin-bottom: 20px;

  border-radius: 2px;
  font-size: 16px;

  box-sizing: border-box;
  padding: 10px;
  font-weight: 600;

  color: black;
`;
const ImgContainer = styled.img`
  margin-bottom: -12rem;
  margin-top: -10rem;
  width: auto;
  height: 500px;
`;
const ErrorMessage = styled.p`
  color: red;
`;

const LoginContainer = (props) => {
  const { isAuthenticated, requiresOrganizationSelection } = props.auth;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();
  const [maintenanceMessage, setMaintenanceMessage] = useState("");
  let { errorMessage } = props.auth;
  const isOffline = !useOnlineStatus();
  const { showAlert } = useAlert();
  useEffect(() => {
    if (errorMessage) {
      showAlert("error", errorMessage);
    }
  }, [errorMessage]);
  useEffect(() => {
    getMaintenanceMessage().then((res) => {
      let dto = res.data;
      if (dto.isEnabled) {
        setMaintenanceMessage(dto.text);
      }
    });
  }, []);
  const handleKeyDown = (e) => {
    if (e.code == "Enter") {
      doAuthorize();
    }
  };
  const doAuthorize = () => {
    const creds = {
      email: email.trim(),
      password: password.trim(),
      url: location.pathname,
    };
    props.loginUser(creds);
  };
  if (isAuthenticated) {
    const qs = queryString.parse(props.location.search);
    if (qs.redirect) {
      return <Redirect to={qs.redirect} />;
    }
    return <Redirect to="/dashboard" />;
  }
  if (requiresOrganizationSelection) {
    const qs = queryString.parse(props.location.search);
    if (qs.redirect) {
      return <Redirect to={"/organizations?redirect=" + qs.redirect} />;
    }
    return <Redirect to="/organizations" />;
  }
  return (
    <div className="content-wrapper-with-footer">
      <div className="login-center-container">
        <ImgContainer src={LynxImage} alt="logo" />
        <LoginPanel>
          <LoginTitle>Sign In</LoginTitle>
          <LoginInput
            id="email"
            type="text"
            name="email"
            placeholder="Email"
            onChange={(event) => setEmail(event.target.value)}
            onKeyDown={handleKeyDown}
          />
          <LoginInput
            type="password"
            id="password"
            name="password"
            placeholder="Password"
            onChange={(event) => setPassword(event.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Button
            id="loginBtn"
            className="login-btn"
            onClick={doAuthorize}
            variant="contained"
          >
            Sign In
          </Button>
          <Button
            className="mt-2"
            onClick={() => props.history.push("/forgot-password")}
          >
            Forgot Password?
          </Button>

          <ErrorMessage className="mt-2 mb-1">{errorMessage}</ErrorMessage>
          {isOffline && (
            <ErrorMessage>
              Offline login is not available. Users must login while connected
              to the internet every 30 days for offline functionality.
            </ErrorMessage>
          )}

          {!_.isEmpty(maintenanceMessage) && (
            <div className="text-red">{maintenanceMessage}</div>
          )}

          <div className="mt-1 ">Version {process.env.REACT_APP_VERSION}</div>
        </LoginPanel>
        <MainFooter />
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  loginUser: (creds) => dispatch(loginUser(creds)),
  loadAccount: () => dispatch(loadAccount()),
  loadUserOrg: () => dispatch(loadUserOrg()),
});

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
