import queryString from "query-string";
const { apiService } = require("../apiService");

const BASE_ROUTE = "/assets";

const assetsEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    getAssetsLookups: builder.query({
      query: (showAll: boolean | undefined = false) =>
        `${BASE_ROUTE}/lookup?showAll=${showAll}`,
    }),
    getAssets: builder.query({
      query: (params: any) => `${BASE_ROUTE}?${queryString.stringify(params)}`,
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination"); // Access headers
        return { data: response, pagination }; // Include headers in the return object
      },
    }),
  }),
});

export const { useGetAssetsLookupsQuery, useGetAssetsQuery } = assetsEndpoint;
