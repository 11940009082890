import React from "react";
import LockIcon from "@mui/icons-material/Lock";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import _ from "lodash";
import { dateUtil } from "../../../services/date-util";
import { roleMatch } from "actions/auth";
import { UserRoles } from "types/enums";
import { EventCustomFieldDto, EventDto } from "types";
import { tagParseToChip } from "services/helpers/tagParser";

interface Props {
  event: EventDto;
  eventLocked: boolean;
  customFields: EventCustomFieldDto[];
  handleEdit: (section: string) => void;
}

const EventAdditionalFieldsDetails: React.FC<Props> = ({
  event,
  eventLocked,
  customFields,
  handleEdit,
}) => {
  const getCustomFieldValue = (field: EventCustomFieldDto) => {
    const eventField = event.eventCustomFieldValues?.find(
      (x) => x.fieldName === field.fieldName
    );
    if (eventField) {
      switch (_.toLower(eventField.fieldType)) {
        case "date":
          return eventField.dateValue;
        case "datetime":
          return dateUtil.convertDateTimeToLocal(eventField.dateTimeValue!);
        case "number":
          return eventField.numericValue;
        case "checkbox":
          return eventField.booleanValue ? "true" : "false";
        case "multiselect":
          return _.orderBy(eventField.textValue?.split("|")).map((value) => (
            <Chip
              className="mr-1"
              label={value}
              variant="outlined"
              key={value}
            />
          ));
        case "textarea":
          return tagParseToChip(eventField.textValue || "");
        default:
          return eventField.textValue;
      }
    }
    return "";
  };

  const userRoleCanSave = roleMatch([
    UserRoles.EventsAdministrator,
    UserRoles.EventsEditor,
    UserRoles.EventsContributor,
  ]);

  return (
    <div className="w-100 d-flex justify-content-center">
      <Card variant="outlined" sx={{ width: "100%" }} className="lynx-card">
        <CardHeader
          className="lynx-card-header"
          title="Additional Fields"
          action={
            userRoleCanSave &&
            (eventLocked ? (
              <LockIcon
                className="mt-3 mr-3"
                color="disabled"
                fontSize="small"
              />
            ) : (
              <Button
                id="editEventAdditionalDetailsBtn"
                onClick={() => handleEdit("additional-fields")}
                disabled={eventLocked}
              >
                Edit
              </Button>
            ))
          }
        />
        <CardContent>
          <Grid container spacing={1}>
            {_.orderBy(customFields, ["position"]).map((field) => (
              <Grid item xs={12} sm={6} key={field.id}>
                <div className="details-label-text">{field.fieldLabel}</div>
                <div className="details-value-text">
                  {getCustomFieldValue(field) || "-"}
                </div>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default EventAdditionalFieldsDetails;
