import queryString from "query-string";
import { BaseParams } from "types";
import { RtkTagTypes } from "types/enums";

const { apiService } = require("../apiService");

const BASE_ROUTE = "/inspections";

const inspectionsEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    getInspections: builder.query({
      query: (params: BaseParams) =>
        `${BASE_ROUTE}?${queryString.stringify(params)}`,
      providesTags: [RtkTagTypes.GetInspections],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),
    getInspection: builder.query({
      query: (id: number) => `${BASE_ROUTE}/${id}`,
      providesTags: (result: any, error: any, id: any) => [
        { type: RtkTagTypes.GetInspections, id },
      ],
    }),
    createInspection: builder.mutation({
      query: ({
        formData,
        isCompleted,
      }: {
        formData: FormData;
        isCompleted: boolean;
      }) => ({
        url: `${BASE_ROUTE}?isCompleted=${isCompleted}`,
        method: "POST",
        body: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }),
      invalidatesTags: [RtkTagTypes.GetInspections],
    }),
    updateInspection: builder.mutation({
      query: ({
        id,
        dto,
        isCompleted,
        updateInspectionFormOnly,
      }: {
        id: number;
        dto: any;
        isCompleted: boolean;
        updateInspectionFormOnly: boolean;
      }) => ({
        url: `${BASE_ROUTE}/${id}?isCompleted=${isCompleted}&updateInspectionFormOnly=${updateInspectionFormOnly}`,
        method: "PUT",
        body: dto,
      }),
      invalidatesTags: (result: any, error: any, { id }: any) => [
        { type: RtkTagTypes.GetInspection, id },
        RtkTagTypes.GetInspections,
      ],
    }),
    updateInspectionDetails: builder.mutation({
      query: ({ id, dto }: { id: number; dto: any }) => ({
        url: `${BASE_ROUTE}/${id}/details`,
        method: "PUT",
        body: dto,
      }),
      invalidatesTags: (result: any, error: any, { id }: any) => [
        { type: RtkTagTypes.GetInspection, id },
        RtkTagTypes.GetInspections,
      ],
    }),
    deleteInspection: builder.mutation({
      query: (id: number) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetInspections],
    }),
    getInspectionCountByFilterId: builder.query({
      query: (filterId: number) => `${BASE_ROUTE}/count/${filterId}`,
      providesTags: [RtkTagTypes.GetInspectionCount],
    }),
  }),
});

export const {
  useGetInspectionsQuery,
  useLazyGetInspectionsQuery,
  useGetInspectionQuery,
  useLazyGetInspectionQuery,
  useCreateInspectionMutation,
  useUpdateInspectionMutation,
  useUpdateInspectionDetailsMutation,
  useDeleteInspectionMutation,
  useGetInspectionCountByFilterIdQuery,
  useLazyGetInspectionCountByFilterIdQuery,
} = inspectionsEndpoint;
