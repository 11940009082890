import React, { useState, useEffect } from "react";
import { Grid, Card } from "components/lynx-components";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { AddLookupHeader } from "./../add-lookup-header";
import { getLookups } from "./../../../../services/lookup";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import { EditLookupModal } from "./../edit-lookup-modal";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
export function ActionSettings() {
  const editFieldProps = {
    field: "Actions",
    headerName: "",
    width: 50,
    type: "actions",
    resizable: false,
    disableColumnMenu: true,
    disableReorder: true,
    disableExport: true,
    hideSortIcons: true,
  };
  const defaultColumns = [
    {
      ...editFieldProps,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  handleEditMonEventType(params.row);
                }}
                aria-label="Edit"
                size="small"
                className="grid-edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "code",
      headerName: "Name",
      width: 200,
      type: "string",
    },

    {
      field: "description",
      headerName: "Description",
      width: 250,
      type: "string",
    },
  ];

  const [actionCategories, setActionCategories] = useState([]);
  const [actionCategoriesLoading, setActionCategoriesLoading] = useState(true);
  const [actionEvidenceRequired, setActionEvidenceRequired] = useState([]);
  const [actionEvidenceRequiredLoading, setActionEvidenceRequiredLoading] =
    useState(true);
  const [showLookupModal, setShowLookupModal] = useState(false);
  const [selectedLookup, setSelectedLookup] = useState({});
  const history = useHistory();
  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    loadActionCategories();
    loadActionEvidenceRequired();
  };

  const loadActionCategories = () => {
    getLookups("ActionCategory").then((res) => {
      setActionCategories(res.data);
      setActionCategoriesLoading(false);
    });
  };

  const loadActionEvidenceRequired = () => {
    getLookups("ActionEvidenceRequired").then((res) => {
      setActionEvidenceRequired(res.data);
      setActionEvidenceRequiredLoading(false);
    });
  };

  const handleEditMonEventType = (lookup) => {
    setShowLookupModal(true);
    setSelectedLookup(lookup);
  };

  const handleLookupModalClose = () => {
    setShowLookupModal(false);
    setSelectedLookup({});
  };

  const handleLookupSave = () => {
    if (
      selectedLookup &&
      selectedLookup.lookupType &&
      selectedLookup.lookupType.toLowerCase() == "actioncategory"
    ) {
      loadActionCategories();
    }
    if (
      selectedLookup &&
      selectedLookup.lookupType &&
      selectedLookup.lookupType.toLowerCase() == "actionevidencerequired"
    ) {
      loadActionEvidenceRequired();
    }
    handleLookupModalClose();
  };

  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };
  return (
    <Grid>
      <Paper>
        <Grid.Row className="ml-0 mr-0 subpage-header-row-breadcrumbs mb-5">
          <Grid.Col width={12}>
            <div className="d-flex h-100">
              <Typography
                className="align-self-center"
                variant="h3"
                component="div"
              >
                Action Settings
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb" className="mb-2">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "/settings")}
              >
                Settings
              </Link>

              <Typography color="text.primary">Action Settings</Typography>
            </Breadcrumbs>
          </Grid.Col>
        </Grid.Row>
      </Paper>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Action Categories
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              An Action Category is a broad-based category for data entry on the
              Add Action form using the Linked Actions button on the Event
              Details page (e.g., corrective action, preventative action).
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Name"
              descriptionLabel="Description"
              lookupType="ActionCategory"
              lookupName="Action Category"
              handleAddLookup={() => loadActionCategories()}
            ></AddLookupHeader>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={actionCategories}
                columns={defaultColumns}
                disableMultipleRowSelection={true}
                loading={actionCategoriesLoading}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Action Evidence Required
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              Action Evidence Required is the required evidence needed to verify
              and close out an Action (eg., comment, picture attachment, video
              attachment).
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Name"
              descriptionLabel="Description"
              lookupType="ActionEvidenceRequired"
              lookupName="Action Evidence Required"
              handleAddLookup={() => loadActionEvidenceRequired()}
            ></AddLookupHeader>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={actionEvidenceRequired}
                columns={defaultColumns}
                disableMultipleRowSelection={true}
                loading={actionEvidenceRequiredLoading}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>

      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showLookupModal}
        onClose={handleLookupModalClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <EditLookupModal
          lookup={selectedLookup}
          codeLabel="Name"
          descriptionLabel="Description"
          handleModalClose={handleLookupModalClose}
          handleLookupSave={handleLookupSave}
          lookupName={
            selectedLookup.lookupType
              ? selectedLookup.lookupType.toLowerCase() == "unit"
                ? "Unit"
                : selectedLookup.lookupType.toLowerCase() ==
                  "monitoringeventtype"
                ? "Monitoring Event Type"
                : ""
              : ""
          }
        />
      </Dialog>
    </Grid>
  );
}
