import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getOrganizationUserId } from "actions/auth";
import { LocalStorageKeys, RtkTagTypes } from "types/enums";
import queryString from "query-string";

export const mainUrl =
  process.env.REACT_APP_API_URL ?? "https://localhost:44396/";

const baseUrl = `${mainUrl}api`;

// Track if a refresh request is in progress
let isRefreshing = false;
let refreshSubscribers: ((newToken: string) => void)[] = [];

// Broadcast the new token to all queued requests
const onRefreshed = (newToken: string) => {
  refreshSubscribers.forEach((callback) => callback(newToken));
  refreshSubscribers = [];
};

export const refreshAccessToken = async () => {
  const redirectPath = window.location.pathname + window.location.search;
  const redirectObject = { redirect: redirectPath };
  var authUrl = `/authorize?${queryString.stringify(redirectObject)}`;
  if (!isRefreshing) {
    isRefreshing = true;
    try {
      const response = await fetch(
        `${baseUrl}/auth/refresh-token/${getOrganizationUserId()}`,
        {
          method: "POST",
          credentials: "include", // Include cookies for refresh token
        }
      );

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem(LocalStorageKeys.AccessToken, data.token);
        localStorage.setItem(LocalStorageKeys.OfflineToken, data.offlineToken);
        // Update the access token
        onRefreshed(data.token); // Notify all queued requests
        return data.token;
      } else {
        // Handle invalid/expired refresh token
        localStorage.removeItem(LocalStorageKeys.AccessToken);
        window.location.href = authUrl; // Redirect to login
        return null;
      }
    } catch (error) {
      console.error("Error refreshing access token:", error);
      localStorage.removeItem(LocalStorageKeys.AccessToken);
      window.location.href = authUrl; // Redirect to login
      return null;
    } finally {
      isRefreshing = false; // Reset the refresh state
    }
  }

  // Wait for the ongoing refresh to complete
  return new Promise<string | null>((resolve) => {
    refreshSubscribers.push(resolve);
  });
};

export const apiService = createApi({
  reducerPath: "API",
  baseQuery: async (args, api, extraOptions) => {
    const baseFetch = fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        const token = localStorage.getItem(LocalStorageKeys.AccessToken);
        if (token) {
          headers.set("authorization", `Bearer ${token}`);
        }
        return headers;
      },
    });

    // Make the API call
    let result = await baseFetch(args, api, extraOptions);

    // If token is expired (401 Unauthorized), refresh and retry
    if (result.error?.status === 401) {
      const newAccessToken = await refreshAccessToken();

      if (newAccessToken) {
        // Retry the original request with the new token
        const updatedHeaders = (headers: any) => {
          headers.set("authorization", `Bearer ${newAccessToken}`);
          return headers;
        };

        result = await baseFetch(
          { ...args, headers: updatedHeaders(args.headers) },
          api,
          extraOptions
        );
      }
    }

    return result;
  },
  endpoints: () => ({}),
  keepUnusedDataFor: 120,
  tagTypes: [...Object.values(RtkTagTypes)],
});
