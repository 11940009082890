import queryString from "query-string";
import { RtkTagTypes } from "types/enums";

const { apiService } = require("../apiService");

const BASE_ROUTE = "/inspectionForms";

const inspectionFormsEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    getInspectionForms: builder.query({
      query: (params: {
        onlyInspectionForms: boolean;
        onlyIncidentForms: boolean;
        onlyWitnessForms: boolean;
      }) => `${BASE_ROUTE}?${queryString.stringify(params)}`,
      providesTags: [RtkTagTypes.GetInspectionForms],
    }),
    getInspectionForm: builder.query({
      query: (id: number) => `${BASE_ROUTE}/${id}`,
      providesTags: (result: any, error: any, id: any) => [
        { type: RtkTagTypes.GetInspectionForm, id },
      ],
    }),
    createInspectionForm: builder.mutation({
      query: (dto: any) => ({
        url: BASE_ROUTE,
        method: "POST",
        body: dto,
      }),
      invalidatesTags: [RtkTagTypes.GetInspectionForms],
    }),
    updateInspectionForm: builder.mutation({
      query: ({ id, dto }: { id: number; dto: any }) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "PUT",
        body: dto,
      }),
      invalidatesTags: (result: any, error: any, { id }: any) => [
        { type: RtkTagTypes.GetInspectionForm, id },
      ],
    }),
    deleteInspectionForm: builder.mutation({
      query: (id: number) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetInspectionForms],
    }),
  }),
});

export const {
  useGetInspectionFormsQuery,
  useLazyGetInspectionFormsQuery,
  useGetInspectionFormQuery,
  useLazyGetInspectionFormQuery,
  useCreateInspectionFormMutation,
  useUpdateInspectionFormMutation,
  useDeleteInspectionFormMutation,
} = inspectionFormsEndpoint;
