import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GroupIcon from "@mui/icons-material/Group";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import MuiGrid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Dimmer, Form, Grid } from "components/lynx-components";
import { roleMatch } from "../../../actions/auth";
import { validationService } from "../../../services";

import { dateUtil } from "../../../services/date-util";
import { getLookups } from "../../../services/lookup";
import {
  EntityTypes,
  EventContactTypes,
  LookupTypes,
  UserRoles,
} from "../../../types/enums";
import { LynxTextArea } from "../../form-controls/lynx-form-controls";
import { LynxDialog } from "../../lynx-dialog";
import { CorrespondenceContactChip } from "./correspondence-contact-chip";
import useAlert from "hooks/useAlert";
import {
  useCreateCorrespondenceLogMutation,
  useDeleteCorrespondenceLogMutation,
  useUpdateCorrespondenceLogMutation,
} from "services/rtkApi/endpoints/correspondenceLogs";
import { useLazySearchContactsQuery } from "services/rtkApi/endpoints/contacts";
import {
  ContactSearchResultDto,
  CorrespondenceLogContactDto,
  CorrespondenceLogDto,
  EventDto,
  LookupDto,
} from "types";
import { AttachmentViewer } from "components/attachment-viewer";
import lynxColors from "modules/lynxColors";
interface CorrespondenceLogModalProps {
  correspondenceLog?: CorrespondenceLogDto;
  event: EventDto;
  eventLocked: boolean;
  handleClose: () => void;
  open: boolean;
}
//
export function CorrespondenceLogModal(props: CorrespondenceLogModalProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [correspondenceLog, setCorrespondenceLog] =
    useState<CorrespondenceLogDto | null>(null);
  interface FormState {
    correspondenceDateTimeUtc?: string;
    correspondenceDateTimeUtcError?: string;
    subject?: string;
    correspondenceTypeId?: number;
    correspondenceTypeIdError?: string;
    subjectError?: string;
    details?: string;
    fromDetails?: string;
    toDetails?: string;
    toDetailsError?: string;
    fromDetailsError?: string;
    ccDetails?: string;
  }

  const [formState, setFormState] = useState<FormState>({
    correspondenceDateTimeUtc: "",
    correspondenceDateTimeUtcError: "",
    subject: "",
    correspondenceTypeId: 0,
    correspondenceTypeIdError: "",
    subjectError: "",
    details: "",
    fromDetails: "",
    toDetails: "",
    ccDetails: "",
  });
  const [showDelete, setShowDelete] = useState(false);
  const [toOptions, setToOptions] = useState<ContactSearchResultDto[]>([]);
  const [correspondenceTypes, setCorrespondenceTypes] = useState<LookupDto[]>(
    []
  );

  const [fromOptions, setFromOptions] = useState<ContactSearchResultDto[]>([]);
  const [ccOptions, setCcOptions] = useState<ContactSearchResultDto[]>([]);
  const [ccOptionsLoading, setCcOptionsLoading] = useState(false);
  const [toOptionsLoading, setToOptionsLoading] = useState(false);
  const [fromOptionsLoading, setFromOptionsLoading] = useState(false);
  const [correspondenceLogContacts, setCorrespondenceLogContacts] = useState<
    CorrespondenceLogContactDto[]
  >([]);
  const [autoCompleteToContactValue, setAutoCompleteToContactValue] =
    useState(null);
  const [autoCompleteToContactInputValue, setAutoCompleteToContactInputValue] =
    useState("");

  const [autoCompleteFromContactValue, setAutoCompleteFromContactValue] =
    useState(null);
  const [
    autoCompleteFromContactInputValue,
    setAutoCompleteFromContactInputValue,
  ] = useState("");
  const [autoCompleteCcContactValue, setAutoCompleteCcContactValue] =
    useState(null);
  const [autoCompleteCcContactInputValue, setAutoCompleteCcContactInputValue] =
    useState("");
  const [searchTrigger] = useLazySearchContactsQuery();
  const [addTrigger] = useCreateCorrespondenceLogMutation();
  const [updateTrigger] = useUpdateCorrespondenceLogMutation();
  const [deleteTrigger] = useDeleteCorrespondenceLogMutation();
  useEffect(() => {
    if (props.correspondenceLog) {
      setCorrespondenceLog(props.correspondenceLog);
      setFormStateFromProps(props.correspondenceLog);
    }
    getLookups(LookupTypes.CorrespondenceLogType).then((res) => {
      setCorrespondenceTypes(res.data);
      setIsLoading(false);
    });
  }, []);

  const { showAlert } = useAlert();

  const setFormStateFromProps = (correspondenceLog: CorrespondenceLogDto) => {
    setFormState({
      correspondenceDateTimeUtc: dateUtil.convertDateTimeToLocal(
        correspondenceLog.correspondenceDateTimeUtc
      ),
      correspondenceTypeId: correspondenceLog.correspondenceTypeId,
      subject: correspondenceLog.subject,
      details: correspondenceLog.details,
      fromDetails: correspondenceLog.fromDetails,
      toDetails: correspondenceLog.toDetails,
      ccDetails: correspondenceLog.ccDetails,
    });
    if (correspondenceLog.correspondenceLogContacts) {
      setCorrespondenceLogContacts(correspondenceLog.correspondenceLogContacts);
    }
  };
  useEffect(() => {
    if (autoCompleteToContactInputValue === "") {
      setToOptions(
        autoCompleteToContactValue ? [autoCompleteToContactValue] : []
      );
      return;
    }
    setToOptionsLoading(true);
    searchTrigger({
      query: autoCompleteToContactInputValue,
      includeUsers: true,
    }).then((res) => {
      if (res.data) {
        setToOptions(res.data as ContactSearchResultDto[]);
        setToOptionsLoading(false);
      }
    });
  }, [autoCompleteToContactValue, autoCompleteToContactInputValue]);

  useEffect(() => {
    if (autoCompleteFromContactInputValue === "") {
      setFromOptions(
        autoCompleteFromContactValue ? [autoCompleteFromContactValue] : []
      );
      return;
    }
    setFromOptionsLoading(true);
    searchTrigger({
      query: autoCompleteFromContactInputValue,
      includeUsers: true,
    }).then((res) => {
      if (res.data) {
        setFromOptions(res.data as ContactSearchResultDto[]);
        setFromOptionsLoading(false);
      }
    });
  }, [autoCompleteFromContactValue, autoCompleteFromContactInputValue]);

  useEffect(() => {
    if (autoCompleteCcContactInputValue === "") {
      setCcOptions(
        autoCompleteCcContactValue ? [autoCompleteCcContactValue] : []
      );
      return;
    }
    setCcOptionsLoading(true);
    searchTrigger({
      query: autoCompleteCcContactInputValue,
      includeUsers: true,
    }).then((res) => {
      if (res.data) {
        setCcOptions(res.data as ContactSearchResultDto[]);
        setCcOptionsLoading(false);
      }
    });
  }, [autoCompleteCcContactValue, autoCompleteCcContactInputValue]);
  useEffect(() => {}, [correspondenceTypes]);
  const handleInputChange = (e: any) => {
    let newState = { ...formState };
    let name = e.target.name;
    let value = e.target.value;
    _.set(newState, name, value);
    setFormState(newState);
  };
  const getContactOptions = (type: string) => {
    let options =
      type == "from"
        ? fromOptions
        : type == "to"
        ? toOptions
        : type == "cc"
        ? ccOptions
        : null;
    if (!options) return [];
    const isTo = type == "to";
    const isFrom = type == "from";
    const isCc = type == "cc";
    var contactOptions = options.filter(
      (x: ContactSearchResultDto) =>
        x.type == EventContactTypes.Contact &&
        !correspondenceLogContacts
          .filter(
            (x) =>
              x.contactId != null &&
              x.isFrom == isFrom &&
              x.isTo == isTo &&
              x.isCc == isCc
          )
          .map((y) => y.contactId)
          .includes(x.id)
    );
    var contactGroupOptions = options.filter(
      (x: ContactSearchResultDto) =>
        x.type == EventContactTypes.ContactGroup &&
        !correspondenceLogContacts
          .filter(
            (x) =>
              x.contactGroupId != null &&
              x.isFrom == isFrom &&
              x.isTo == isTo &&
              x.isCc == isCc
          )
          .map((y) => y.contactGroupId)
          .includes(x.id)
    );

    var userOptions = options.filter(
      (x: ContactSearchResultDto) =>
        x.type == EventContactTypes.User &&
        !correspondenceLogContacts
          .filter(
            (x) =>
              x.userId != null &&
              x.isFrom == isFrom &&
              x.isTo == isTo &&
              x.isCc == isCc
          )
          .map((y) => y.userId)
          .includes(x.id)
    );

    var allOptions = [
      ...contactOptions,
      ...contactGroupOptions,
      ...userOptions,
    ];
    return _.sortBy(allOptions, [
      (contact: ContactSearchResultDto) => contact.name?.toLowerCase(),
    ]);
  };
  const handleContactSelected = (e: any, value: any, type: string) => {
    if (value) {
      if (type == "from") {
        setAutoCompleteFromContactValue(null);
      }
      if (type == "to") {
        setAutoCompleteToContactValue(null);
      }
      if (type == "cc") {
        setAutoCompleteCcContactValue(null);
      }

      let newContacts = _.cloneDeep(correspondenceLogContacts);

      const contactsToSet: CorrespondenceLogContactDto[] = [
        ...newContacts,
        { ...transformContactLookupToCoresspondenceContact(value, type) },
      ];
      setCorrespondenceLogContacts(contactsToSet);
    }
  };

  const transformContactLookupToCoresspondenceContact = (
    contact: ContactSearchResultDto,
    type: string
  ): CorrespondenceLogContactDto => {
    return {
      userId: contact.type == EventContactTypes.User ? contact.id : null,
      userFullName:
        contact.type == EventContactTypes.User ? contact.name : undefined,
      contactId: contact.type == EventContactTypes.Contact ? contact.id : null,
      contactFullName:
        contact.type == EventContactTypes.Contact ? contact.name : undefined,
      contactGroupId:
        contact.type == EventContactTypes.ContactGroup ? contact.id : null,
      contactGroupName:
        contact.type == EventContactTypes.ContactGroup
          ? contact.name
          : undefined,
      isFrom: type == "from" ? true : false,
      isTo: type == "to" ? true : false,
      isCc: type == "cc" ? true : false,
    };
  };

  const autoCompleteContactInputChange = (
    e: any,
    newValue: string,
    reason: any,
    type: string
  ) => {
    if (reason === "reset") {
      if (type == "from") {
        setAutoCompleteFromContactValue(null);
      }
      if (type == "to") {
        setAutoCompleteToContactValue(null);
      }
      if (type == "cc") {
        setAutoCompleteCcContactValue(null);
      }

      return;
    } else {
      if (type == "from") {
        setAutoCompleteFromContactInputValue(newValue);
      }
      if (type == "to") {
        setAutoCompleteToContactInputValue(newValue);
      }
      if (type == "cc") {
        setAutoCompleteCcContactInputValue(newValue);
      }
    }
  };
  const handleContactRemove = (contact: CorrespondenceLogContactDto) => {
    var allContacts = [...correspondenceLogContacts];
    var contactIndex = null;
    if (contact.userId) {
      contactIndex = allContacts.findIndex(
        (x) =>
          x.userId == contact.userId &&
          x.isTo == contact.isTo &&
          x.isFrom == contact.isFrom &&
          x.isCc == contact.isCc
      );
    }
    if (contact.contactId) {
      contactIndex = allContacts.findIndex(
        (x) =>
          x.contactId == contact.contactId &&
          x.isTo == contact.isTo &&
          x.isFrom == contact.isFrom &&
          x.isCc == contact.isCc
      );
    }
    if (contact.contactGroupId) {
      contactIndex = allContacts.findIndex(
        (x) =>
          x.contactGroupId == contact.contactGroupId &&
          x.isTo == contact.isTo &&
          x.isFrom == contact.isFrom &&
          x.isCc == contact.isCc
      );
    }
    if (contactIndex != null) {
      allContacts.splice(contactIndex, 1);
    }

    setCorrespondenceLogContacts(allContacts);
  };
  const renderOptions = (props: any, option: any) => (
    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
      {option.type == EventContactTypes.ContactGroup && (
        <GroupIcon color="disabled" className="mr-2" />
      )}
      {option.type == EventContactTypes.User && (
        <AccountCircleIcon color="disabled" className="mr-2" />
      )}
      {option.name}
      <Typography variant="body2" className="ml-2">
        {option.contactGroupName}
      </Typography>
    </Box>
  );
  const logForm = (
    <Dimmer active={isLoading} loader>
      <Grid>
        <Grid.Row>
          <Grid.Col md={6} width={12}>
            <Form.Group isRequired label="Correspondence Date Time">
              <Form.Input
                type="datetime-local"
                value={formState.correspondenceDateTimeUtc}
                name="correspondenceDateTimeUtc"
                onChange={handleInputChange}
                error={formState.correspondenceDateTimeUtcError}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={6} width={12}>
            <Form.Group isRequired label="Correspondence Type">
              <Form.Select
                onChange={handleInputChange}
                name={`correspondenceTypeId`}
                value={formState.correspondenceTypeId}
                error={formState.correspondenceTypeIdError}
              >
                <option value={""}></option>
                {correspondenceTypes.map((type) => (
                  <option value={type.id} key={type.id}>
                    {type.code}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={12} width={12}>
            <Form.Group isRequired={true} label="Subject">
              <Form.Input
                type="text"
                onChange={handleInputChange}
                name={`subject`}
                error={formState.subjectError}
                value={formState.subject}
              ></Form.Input>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={12} width={12}>
            <Form.Group label="Details">
              <LynxTextArea
                onChange={handleInputChange}
                name={`details`}
                value={formState.details}
              ></LynxTextArea>
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={6} width={12}>
            <Box
              sx={{ border: `1px solid ${lynxColors.gray}`, padding: "0.5rem" }}
            >
              <Form.Group isRequired label="Correspondence From:">
                <LynxTextArea
                  onChange={handleInputChange}
                  name={`fromDetails`}
                  className="mb-2"
                  value={formState.fromDetails}
                  error={formState.fromDetailsError}
                ></LynxTextArea>
                <Autocomplete
                  loading={fromOptionsLoading}
                  filterOptions={(x) => x}
                  disabled={props.eventLocked}
                  id="contact-grouped-search"
                  options={getContactOptions("from")}
                  noOptionsText={
                    !autoCompleteFromContactInputValue
                      ? "Enter text to search"
                      : "No options"
                  }
                  getOptionLabel={(option) =>
                    option.name + " " + option.contactGroupName
                  }
                  renderOption={renderOptions}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Search users, contacts or contact groups"
                    />
                  )}
                  onChange={(e, value) => {
                    handleContactSelected(e, value, "from");
                  }}
                  value={autoCompleteFromContactValue}
                  inputValue={autoCompleteFromContactInputValue}
                  onInputChange={(e, newValue, reason) => {
                    autoCompleteContactInputChange(e, newValue, reason, "from");
                  }}
                />{" "}
                <MuiGrid container spacing={1} className="mt-2">
                  {_.sortBy(
                    correspondenceLogContacts.filter((x) => x.isFrom),
                    [
                      (contact) =>
                        contact.userId
                          ? contact.userFullName?.toLowerCase()
                          : contact.contactId
                          ? contact.contactFullName?.toLowerCase()
                          : contact.contactGroupId
                          ? contact.contactGroupName?.toLowerCase()
                          : "",
                    ]
                  ).map((contact, i) => (
                    <MuiGrid item key={`corLogContact_${i}`}>
                      <CorrespondenceContactChip
                        contact={contact}
                        onDelete={handleContactRemove}
                        className={""}
                      />
                    </MuiGrid>
                  ))}
                </MuiGrid>
              </Form.Group>
            </Box>
          </Grid.Col>
          <Grid.Col md={6} width={12}>
            <Box
              sx={{ border: `1px solid ${lynxColors.gray}`, padding: "0.5rem" }}
            >
              <Form.Group isRequired label="Correspondence To:">
                <LynxTextArea
                  onChange={handleInputChange}
                  name={`toDetails`}
                  className="mb-2"
                  value={formState.toDetails}
                  error={formState.toDetailsError}
                ></LynxTextArea>
                <Autocomplete
                  loading={toOptionsLoading}
                  filterOptions={(x) => x}
                  disabled={props.eventLocked}
                  id="contact-grouped-search"
                  options={getContactOptions("to")}
                  noOptionsText={
                    !autoCompleteToContactInputValue
                      ? "Enter text to search"
                      : "No options"
                  }
                  getOptionLabel={(option) =>
                    option.name + " " + option.contactGroupName
                  }
                  renderOption={renderOptions}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Search users, contacts or contact groups"
                    />
                  )}
                  onChange={(e, value) => {
                    handleContactSelected(e, value, "to");
                  }}
                  value={autoCompleteToContactValue}
                  inputValue={autoCompleteToContactInputValue}
                  onInputChange={(e, newValue, reason) => {
                    autoCompleteContactInputChange(e, newValue, reason, "to");
                  }}
                />{" "}
                <MuiGrid container spacing={1} className="mt-2">
                  {_.sortBy(
                    correspondenceLogContacts.filter((x) => x.isTo),
                    [
                      (contact) =>
                        contact.userId
                          ? contact.userFullName?.toLowerCase()
                          : contact.contactId
                          ? contact.contactFullName?.toLowerCase()
                          : contact.contactGroupId
                          ? contact.contactGroupName?.toLowerCase()
                          : "",
                    ]
                  ).map((contact, i) => (
                    <MuiGrid item key={`corLogContact_${i}`}>
                      <CorrespondenceContactChip
                        contact={contact}
                        onDelete={handleContactRemove}
                        className={""}
                      />
                    </MuiGrid>
                  ))}
                </MuiGrid>
              </Form.Group>
            </Box>
          </Grid.Col>
          <Grid.Col md={6} width={12} className="mt-2">
            <Box
              sx={{ border: `1px solid ${lynxColors.gray}`, padding: "0.5rem" }}
            >
              <Form.Group label="Correspondence Cc:">
                <LynxTextArea
                  onChange={handleInputChange}
                  name={`ccDetails`}
                  className="mb-2"
                  value={formState.ccDetails}
                ></LynxTextArea>
                <Autocomplete
                  loading={ccOptionsLoading}
                  filterOptions={(x) => x}
                  disabled={props.eventLocked}
                  id="contact-grouped-search"
                  options={getContactOptions("cc")}
                  noOptionsText={
                    !autoCompleteCcContactInputValue
                      ? "Enter text to search"
                      : "No options"
                  }
                  getOptionLabel={(option) =>
                    option.name + " " + option.contactGroupName
                  }
                  renderOption={renderOptions}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Search users, contacts or contact groups"
                    />
                  )}
                  onChange={(e, value) => {
                    handleContactSelected(e, value, "cc");
                  }}
                  value={autoCompleteCcContactValue}
                  inputValue={autoCompleteCcContactInputValue}
                  onInputChange={(e, newValue, reason) => {
                    autoCompleteContactInputChange(e, newValue, reason, "cc");
                  }}
                />{" "}
                <MuiGrid container spacing={1} className="mt-2">
                  {_.sortBy(
                    correspondenceLogContacts.filter((x) => x.isCc),
                    [
                      (contact) =>
                        contact.userId
                          ? contact.userFullName?.toLowerCase()
                          : contact.contactId
                          ? contact.contactFullName?.toLowerCase()
                          : contact.contactGroupId
                          ? contact.contactGroupName?.toLowerCase()
                          : "",
                    ]
                  ).map((contact, i) => (
                    <MuiGrid item key={`corLogContact_${i}`}>
                      <CorrespondenceContactChip
                        contact={contact}
                        onDelete={handleContactRemove}
                        className={""}
                      />
                    </MuiGrid>
                  ))}
                </MuiGrid>
              </Form.Group>
            </Box>
          </Grid.Col>
          <Grid.Col md={12} width={12} className="mt-2">
            <AttachmentViewer
              isExistingEntity={
                props.correspondenceLog != null &&
                props.correspondenceLog != undefined
              }
              entityType={EntityTypes.CorrespondenceLog}
              disableDelete={props.eventLocked}
              entityId={
                props.correspondenceLog ? props.correspondenceLog.id : undefined
              }
              handleSetUploadedFiles={(files: File[]) => {
                setUploadedFiles(files);
              }}
              rowHeight={300}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Dimmer>
  );
  const handleDelete = () => {
    setShowDelete(true);
  };
  const handleSaveLog = () => {
    if (!validateDataForSave()) {
      return;
    }
    setIsLoading(true);
    let dto = validationService.unsetErrors(
      formState,
      "correspondenceDateTimeUtcError",
      "correspondenceTypeIdError",
      "subjectError",
      "toDetailsError",
      "fromDetailsError"
    );
    dto.correspondenceLogContacts = correspondenceLogContacts;
    dto.eventId = props.event.id;
    dto.correspondenceDateTimeUtc = moment(dto.correspondenceDateTimeUtc)
      .utc()
      .format();
    if (correspondenceLog != null) {
      updateTrigger({ id: correspondenceLog.id, dto: dto }).then((res) => {
        if (res.data) {
          showAlert("success", "Correspondence log saved.");
        }
        props.handleClose();
      });
    } else {
      let form = new FormData();
      var attachmentsToUpload = [];
      if (!_.isEmpty(uploadedFiles)) {
        for (var index = 0; index < uploadedFiles.length; index++) {
          let file = uploadedFiles[index];
          attachmentsToUpload.push({
            name: file.name,
            blob: file.slice(0, file.size, file.type),
          });
          var element = uploadedFiles[index];
          form.append("file", element);
        }
      }
      let dataString = JSON.stringify(dto);

      form.append("data", dataString);
      addTrigger(form).then((res) => {
        if (res.data) {
          showAlert("success", "Correspondence log created.");
        }

        props.handleClose();
      });
    }
  };
  const deleteCorLog = () => {
    deleteTrigger(correspondenceLog?.id).then((res) => {
      if (res) {
        showAlert("success", "Correspondence log deleted.");
      }
      props.handleClose();
    });
  };
  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "correspondenceDateTimeUtc",
      "correspondenceDateTimeUtcError",
      "Correspondence Date Time"
    );
    validationService.validateRequiredField(
      newState,
      "correspondenceTypeId",
      "correspondenceTypeIdError",
      "Correspondence Type"
    );
    validationService.validateRequiredField(
      newState,
      "subject",
      "subjectError",
      "Subject"
    );

    if (
      _.isEmpty(correspondenceLogContacts.filter((x) => x.isTo)) &&
      !formState.toDetails
    ) {
      newState.toDetailsError =
        "Correspondence To is required. Enter text or search from the list below.";
    } else {
      _.unset(newState, "toDetailsError");
    }
    if (
      _.isEmpty(correspondenceLogContacts.filter((x) => x.isFrom)) &&
      !formState.fromDetails
    ) {
      newState.fromDetailsError =
        "Correspondence From is required. Enter text or search from the list below.";
    } else {
      _.unset(newState, "fromDetailsError");
    }

    isFormValid = !validationService.hasError(
      newState,
      "correspondenceDateTimeUtcError",
      "correspondenceTypeIdError",
      "subjectError",
      "toDetailsError",
      "fromDetailsError"
    );
    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };
  return (
    <>
      <LynxDialog
        dividers
        maxWidth={"lg"}
        open={props.open}
        title={`Add Correspondence Log`}
        fullWidth
        dialogContent={logForm}
        handleDelete={
          correspondenceLog != null && roleMatch([UserRoles.Admin])
            ? handleDelete
            : null
        }
        handleClose={() => {
          props.handleClose();
        }}
        handleSave={handleSaveLog}
      />
      <LynxDialog
        dividers
        open={showDelete}
        title={`Delete Correspondence Log?`}
        description={
          "Are you sure you want to delete this correspondence log? This action cannot be undone."
        }
        handleClose={() => {
          setShowDelete(false);
        }}
        handleConfirm={deleteCorLog}
      />
    </>
  );
}
