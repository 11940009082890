import TagChip from "components/dashboards/events/tag-chip";
import { EventContactTypes } from "types";

export const contactAndUserRegex = /(@|#)\[(.*?)]\((.*?)\)/g;

export const tagParseToChip = (markup: string) => {
  const contactMatches = [...markup.matchAll(contactAndUserRegex)];
  const chipArray = contactMatches.map((value) => {
    const [tag, name, id] = [value[1], value[2], value[3]];
    return tag === "@" ? (
      <TagChip id={id} key={id} type="User" name={name} />
    ) : (
      <TagChip
        id={id}
        key={id}
        type={
          id[0] === "c"
            ? EventContactTypes.Contact
            : EventContactTypes.ContactGroup
        }
        name={name}
      />
    );
  });

  const markupSplit = markup.split(contactAndUserRegex);
  return markupSplit.flatMap((text, index) =>
    index % 4 === 0
      ? [<span key={index}>{text}</span>, chipArray[Math.floor(index / 4)]]
      : []
  );
};
