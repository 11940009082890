import moment from "moment";
export class DateUtil {
  convertDateTimeToLocal(dateTime, withSeconds = false) {
    if (!dateTime) {
      return "";
    }

    return moment
      .utc(dateTime)
      .local()
      .format(withSeconds ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD HH:mm");
  }
  convertColumnDateTimeToLocal(dateTime, withSeconds = false) {
    if (!dateTime) {
      return "";
    }

    return new Date(
      moment
        .utc(dateTime)
        .local()
        .format(withSeconds ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD HH:mm")
    );
  }
  convertDateTimeToLocalDateOnly(dateTime) {
    if (!dateTime) {
      return "";
    }
    return moment.utc(dateTime).local().format("YYYY-MM-DD");
  }
  convertColumnDateTimeToLocalDateOnly(dateTime) {
    if (!dateTime) {
      return null; // Return null if no input, as MUI handles nulls gracefully
    }
    // Parse the input string as a UTC date and ensure no time zone shift
    const utcDate = moment(dateTime).startOf("day");
    // Convert to a Date object in the local timezone
    const localDate = utcDate.toDate();
    return localDate;
  }
  convertDateOnlyToLocal(dateOnly) {
    if (!dateOnly) {
      return "";
    }
    return dateOnly;
  }
  convertColumnDateOnlyToLocal(dateOnly) {
    if (!dateOnly) {
      return "";
    }
    return new Date(dateOnly);
  }
}

export const dateUtil = new DateUtil();
